import { z } from "zod";

// Define the schema for the environment variables
const envSchema = z.object({
  REACT_APP_API_URL: z.string().url(),
  REACT_APP_CLOUD_FRONT_URL: z.string().url(),
  REACT_APP_FRONTEND_URL: z.string().url(),
});

// Parse and validate the environment variables
const parsedEnv = envSchema.safeParse(process.env);

// Check if the parsing was successful
if (!parsedEnv.success) {
  throw new Error(
    `Invalid environment variables: ${JSON.stringify(parsedEnv.error.format())}`,
  );
}

// Export the validated environment variables for use in your application
const env = parsedEnv.data;

declare global {
  namespace NodeJS {
    interface ProcessEnv extends z.infer<typeof envSchema> {}
  }
}

export { env };
