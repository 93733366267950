import { useAuth } from "src/redux/api/hooks/useAuth";
import { SignInForm } from "./sign-in-form";
import { useNavigate } from "react-router-dom";
import { DesktopPoster } from "./desktop-poster";
import { StickyBranding } from "src/components/ui/sticky-branding";

export const SignInView = () => {
  const { isAuthenticated } = useAuth();
  const navigate = useNavigate();

  if (isAuthenticated) {
    navigate("/");
  }

  return (
    <div className="md:grid md:grid-cols-2">
      <StickyBranding />
      <div className="flex min-h-[100dvh] items-center justify-center p-4">
        <div className="w-[300px]">
          <h1 className="font-bold text-2xl my-5">Candidate Login</h1>
          <SignInForm />
        </div>
      </div>
      <div className="hidden md:block">
        <DesktopPoster />
      </div>
    </div>
  );
};
