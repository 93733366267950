export const getBookPrice = (guideBook: string) => {
    const availableBooks = [
      {
        title: "RWO",
        price: {
          EBook: 799,
          PaperBook: 2199,
        },
      },
      {
        title: "ITWO",
        price: {
          EBook: 699,
          PaperBook: 1899,
        },
      },
      {
        title: "HRWO",
        price: {
          EBook: 699,
          PaperBook: 1899,
        },
      },
      {
        title: "DWO",
        price: {
          EBook: 599,
          PaperBook: 1699,
        },
      },
      {
        title: "AWO",
        price: {
          EBook: 499,
          PaperBook: 1599,
        },
      },
    ];
    
    const bookPrice = availableBooks.find(
      (book) => book.title === guideBook.toUpperCase()
    )?.price.EBook;
  
    return bookPrice;
  };
  