import { z } from "zod";

const MB_BYTES = 1000000;

const ACCEPTED_MIME_TYPES = [
  "image/jpeg",
  "image/jpg",
  "image/png",
  "image/webp",
];

const MAX_FILE_SIZE = 1000000;
const ACCEPTED_IMAGE_TYPES = [
  "image/jpeg",
  "image/jpg",
  "image/png",
  "image/webp",
];

export const OrderBookSchema = z.object({
  fullName: z.string().min(1),
  whatsappNumber: z
    .string()
    .regex(/^\d+$/, "Please enter a valid phone number")
    .min(10, "Phone number must be 10 digits")
    .max(10, "Phone number must be 10 digits"),
  completePostalAddress: z.string().min(1),
  email: z.string().email().min(1),
  state: z.enum([
    "Andhra Pradesh",
    "Arunachal Pradesh",
    "Assam",
    "Bihar",
    "Chhattisgarh",
    "Delhi",
    "Goa",
    "Gujarat",
    "Haryana",
    "Himachal Pradesh",
    "Jammu and Kashmir",
    "Jharkhand",
    "Karnataka",
    "Kerala",
    "Madhya Pradesh",
    "Maharashtra",
    "Manipur",
    "Meghalaya",
    "Mizoram",
    "Nagaland",
    "Odisha",
    "Puducherry",
    "Punjab",
    "Rajasthan",
    "Sikkim",
    "Tamil Nadu",
    "Telangana",
    "Tripura",
    "Uttarakhand",
    "Uttar Pradesh",
    "West Bengal",
  ]),
  pinCode: z.string().regex(/^\d+$/).min(1),
  transactionNumber: z.string().min(1),
  transactionProof: z
    .any()
    .refine((file) => file?.size <= MAX_FILE_SIZE, `Max image size is 1MB.`)
    .refine(
      (file) => ACCEPTED_IMAGE_TYPES.includes(file?.type),
      "Only .jpg, .jpeg, .png and .webp formats are supported.",
    ),
});

export const SettingsSchema = z.object({
  address: z.string(),
  aadharNumber: z.string().min(12).max(12).optional(),
  phoneNumber: z.string().min(10).max(13),
  profilePicture: z
    .instanceof(File)
    .superRefine((f, ctx) => {
      if (!ACCEPTED_MIME_TYPES.includes(f.type)) {
        ctx.addIssue({
          code: z.ZodIssueCode.custom,
          message: `File must be one of ${ACCEPTED_MIME_TYPES.join(
            ", ",
          )} but was ${f.type}`,
        });
      }
      if (f.size > 3 * MB_BYTES) {
        ctx.addIssue({
          code: z.ZodIssueCode.too_big,
          type: "array",
          message: `The file must not be larger than ${3 * MB_BYTES} bytes: ${
            f.size
          }`,
          maximum: 3 * MB_BYTES,
          inclusive: true,
        });
      }
    })
    .optional(),
});
