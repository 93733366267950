import { useEffect, useState } from "react";
import { Slot } from "src/lib/entities";
import { convertDateToIST } from "src/lib/utils";
import { useFetchAllSlots } from "./useFetchAllSlots";
import { toast } from "sonner";

interface UseFetchSlotsProps {
  selectedDate: Date;
}

export const useFetchFilteredDateSlots = ({
  selectedDate,
}: UseFetchSlotsProps) => {
  const [filteredSlots, setFilteredSlots] = useState<Slot[]>([]);

  const { slots, isPending } = useFetchAllSlots();

  useEffect(() => {
    if (Array.isArray(slots) && slots) {
      try {
        const filteredSlots = slots.filter((slot: Slot) => {
        const convertedSlotDate = convertDateToIST(
          new Date(slot.date).toUTCString(),
        );
        const convertedSelectedDate = convertDateToIST(
          new Date(selectedDate!).toUTCString(),
        );
        return convertedSlotDate === convertedSelectedDate;
      });
      setFilteredSlots(filteredSlots);
      } catch (error) {
        toast.error("something went wrong please refresh the page!")
        window.location.reload();
      }
      
    }
  }, [selectedDate, slots]);

  return { isPending, filteredSlots };
};
