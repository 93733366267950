import { WelcomeText } from "./welcome-header";
import { ProfileDropdown } from "src/components/ui/profile-dropdown";
import { ProfilePicture } from "src/components/ui/profile-picture";

export const DashboardHeader = () => {
  return (
    <header className="flex w-full justify-between">
      <WelcomeText />
      <div className="hidden lg:block">
        <ProfileDropdown>
          <ProfilePicture />
        </ProfileDropdown>
      </div>
    </header>
  );
};
