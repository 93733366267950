import { zodResolver } from "@hookform/resolvers/zod";
import axios from "axios";
import { Loader2 } from "lucide-react";
import { useRef, useState } from "react";
import { useForm } from "react-hook-form";
import { toast } from "sonner";
import { Button } from "src/components/ui/button";
import { Card, CardContent, CardDescription, CardHeader, CardTitle } from "src/components/ui/card";
import { Form, FormControl, FormDescription, FormField, FormItem, FormLabel, FormMessage } from "src/components/ui/form";
import { Input } from "src/components/ui/input";
import { Select, SelectContent, SelectItem, SelectTrigger, SelectValue } from "src/components/ui/select";
import { getBookPrice } from "src/lib/books";
import { Post } from "src/lib/entities";
import { env } from "src/lib/env";
import { states } from "src/lib/stateList";
import { OrderBookSchema } from "src/schemas/order-book";
import { z } from "zod";
import { PaymentDialog } from "./payment-dialog";

export const OrderBookForm = ({ book }: { book: Post }) => {
  const form = useForm<z.infer<typeof OrderBookSchema>>({
    resolver: zodResolver(OrderBookSchema),
  });

  const submitBtnRef = useRef<HTMLButtonElement>(null);

  const [isPending, setIsPending] = useState(false);

  async function uploadFile() {
    const file = form.getValues("transactionProof");
    if (!file) {
      toast.error("No file selected");
      return;
    }
    try {
      const { data } = await axios.get(
        `${env.REACT_APP_API_URL}/getuploadorderurl?filename=${file.name}`,
      );

      const { Key, url } = data.data;

      await axios.put(url, file);

      return Key;
    } catch {
      toast.error("Upload failed. Something went wrong");
    }
  }

  async function onSubmit(values: z.infer<typeof OrderBookSchema>) {
    const validatedFields = OrderBookSchema.safeParse(values);

    if (!validatedFields.success) {
      toast.error("Invalid fields!");
      return;
    }
    try {
      setIsPending(true);
      const paymentKey = await uploadFile();

      const {
        email,
        whatsappNumber,
        fullName,
        completePostalAddress,
        state,
        pinCode,
        transactionNumber,
      } = validatedFields.data;

      const payload = {
        jobPost: book.toUpperCase(),
        emailId: email,
        fullName: fullName,
        address: {
          PostCode: pinCode,
          State: state,
          address: completePostalAddress,
        },
        phoneNumber: whatsappNumber,
        paymentInfo: [
          { FileUrl: paymentKey, TransactionId: transactionNumber },
        ],
      };

      await axios.post(`${env.REACT_APP_API_URL}/orderbook`, payload, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("jtoken")}`,
        },
      });

      toast.success("Form submitted successfully");
      if (submitBtnRef.current) {
        submitBtnRef.current.disabled = true;
      }
    } catch {
      toast.error("Something went wrong");
    } finally {
      setIsPending(false);
    }
  }
  return (
    <Card className="w-[90vw] max-w-screen-md">
      <CardHeader>
        <CardTitle>
          Order Your {book.toUpperCase()} Basic Recruitment Test Guidebook
        </CardTitle>
        <CardDescription>
          Before ordering your copy of {book.toUpperCase()} Guidebook please
          read, understand properly the instructions and Terms & Conditions for
          ordering.
        </CardDescription>
        <h2 className="text-sm font-medium text-red-500">
          Price {getBookPrice(book)}/-
        </h2>
      </CardHeader>
      <CardContent>
        <Form {...form}>
          <form onSubmit={form.handleSubmit(onSubmit)} className="space-y-2">
            <div className="grid lg:grid-cols-2 gap-x-3">
              <FormField
                control={form.control}
                name="fullName"
                render={({ field }) => (
                  <FormItem>
                    <FormLabel>Full name</FormLabel>
                    <FormControl>
                      <Input placeholder="Your full name" {...field} />
                    </FormControl>
                    <FormMessage />
                  </FormItem>
                )}
              />
              <FormField
                control={form.control}
                name="whatsappNumber"
                render={({ field }) => (
                  <FormItem>
                    <FormLabel>WhatsApp Number</FormLabel>
                    <FormControl>
                      <Input placeholder="Your whatsapp number" {...field} />
                    </FormControl>
                    <FormMessage />
                  </FormItem>
                )}
              />
            </div>
            <FormField
              control={form.control}
              name="email"
              render={({ field }) => (
                <FormItem>
                  <FormLabel>Email address</FormLabel>
                  <FormControl>
                    <Input placeholder="Your email address" {...field} />
                  </FormControl>
                  <FormDescription>
                    Updates will be mailed to this address.
                  </FormDescription>
                  <FormMessage />
                </FormItem>
              )}
            />
            <FormField
              control={form.control}
              name="completePostalAddress"
              render={({ field }) => (
                <FormItem>
                  <FormLabel>Postal Address</FormLabel>
                  <FormControl>
                    <Input placeholder="Your postal address" {...field} />
                  </FormControl>
                  <FormDescription>
                    Complete Postal Address (with house number if available)
                  </FormDescription>
                  <FormMessage />
                </FormItem>
              )}
            />
            <FormField
              control={form.control}
              name="state"
              render={({ field }) => (
                <FormItem>
                  <FormLabel>Choose your state</FormLabel>
                  <Select onValueChange={field.onChange} value={field.value}>
                    <FormControl>
                      <SelectTrigger>
                        <SelectValue placeholder="Select your state" />
                      </SelectTrigger>
                    </FormControl>
                    <SelectContent>
                      {states.map((state) => {
                        return (
                          <SelectItem key={state} value={state}>
                            {state}
                          </SelectItem>
                        );
                      })}
                    </SelectContent>
                  </Select>
                  <FormMessage />
                </FormItem>
              )}
            />
            <FormField
              control={form.control}
              name="pinCode"
              render={({ field }) => (
                <FormItem>
                  <FormLabel>PIN Code</FormLabel>
                  <FormControl>
                    <Input placeholder="Your postal pincode" {...field} />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />

            <PaymentDialog guideBook={book} bookPrice={getBookPrice(book)!} />

            <div className="grid lg:grid-cols-2 gap-x-3">
              <FormField
                control={form.control}
                name="transactionNumber"
                render={({ field }) => (
                  <FormItem>
                    <FormLabel>Transaction Number</FormLabel>
                    <FormControl>
                      <Input placeholder="Your transaction number" {...field} />
                    </FormControl>
                    <FormMessage />
                  </FormItem>
                )}
              />

              <FormField
                control={form.control}
                name="transactionProof"
                render={({ field: { value, onChange, ...fieldProps } }) => (
                  <FormItem>
                    <FormLabel>Upload Proof of the Transaction</FormLabel>
                    <FormControl>
                      <Input
                        {...fieldProps}
                        placeholder="Transaction Proof Image"
                        type="file"
                        accept="image/*"
                        onChange={(event) =>
                          onChange(event.target.files && event.target.files[0])
                        }
                      />
                    </FormControl>
                    <FormMessage />
                  </FormItem>
                )}
              />
            </div>
            <div className="flex w-full justify-center pt-5">
              <Button
                type="submit"
                size="lg"
                ref={submitBtnRef}
                disabled={isPending}
              >
                Submit
                {isPending && <Loader2 className="ml-3 animate-spin" />}
              </Button>
            </div>
          </form>
        </Form>
      </CardContent>
    </Card>
  );
};
