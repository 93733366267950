import { useEffect, useState } from "react";
import { Ebook, EbookSkeleton } from "src/components/ui/e-book";
import { ScrollArea, ScrollBar } from "src/components/ui/scroll-area";
import { FetchedEbook } from "src/lib/entities";
import { useFetchEbooks } from "src/redux/api/hooks/useFetchEbooks";

export const Ebooks = () => {
  const { ebooks, isPending } = useFetchEbooks();
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const timeout = setTimeout(() => {
      setIsLoading(false);
    }, 1000);

    return () => {
      clearTimeout(timeout);
    };
  }, [isPending]);

  if (isLoading || isPending) {
    return <EbookSkeleton />;
  }

  const getJobTitle = (postDetails: string) => {
    const details = postDetails.split(" ");
    if (details.length > 1) {
      return details[0];
    }
    return postDetails;
  };

  const getUniqueBook = () => {
    const books: Record<string, FetchedEbook> = {};
    ebooks.forEach((book) => {
      const title = getJobTitle(book.job);

      if (books[title] && books[title].hasBought) {
        books[title].hasBought = true;
      } else {
        books[title] = { hasBought: book.hasBought, job: title };
      }
    });
    return Object.values(books);
  };

  if (ebooks.length > 0) {
    return (
      <div>
        <div className="flex flex-wrap justify-around">
          {getUniqueBook().map((ebook) => (
            <Ebook
              key={ebook.job}
              hasBought={ebook.hasBought}
              job={ebook.job}
            />
          ))}
        </div>
      </div>
    );
  }

  return (
    <div className="flex justify-center p-20">
      {isPending ? (
        <p className="mt-20 text-2xl">Fetching E-Books... </p>
      ) : (
        <p className="mt-20 text-2xl">Books not available at this moment!</p>
      )}
    </div>
  );
};
