import { Hint } from "src/components/ui/hint";
import { ArrowDown, ArrowUp, ArrowLeft, ArrowRight, Minimize, Maximize } from "lucide-react";
import { Minus, Plus } from "lucide-react";
import { cn } from "src/lib/utils";

interface PositionControlsProps {
  onMoveUp: () => void;
  onMoveDown: () => void;
  onMoveLeft: () => void;
  onMoveRight: () => void;
}

export const PositionControls = ({
  onMoveUp,
  onMoveDown,
  onMoveLeft,
  onMoveRight,
}: PositionControlsProps) => {
  return (
    <div className="grid grid-cols-3">
      <div></div>
      <div className="flex justify-center">
        <Hint label="Move up">
          <ArrowUp
            onClick={onMoveUp}
            className="cursor-pointer border border-white/70 size-6 text-white bg-black"
          />
        </Hint>
      </div>
      <div></div>
      <div className="flex justify-center">
        <Hint label="Move Left">
          <ArrowLeft
            onClick={onMoveLeft}
            className="cursor-pointer border border-white/70 size-6 text-white bg-black"
          />
        </Hint>
      </div>
      <div></div>
      <div className="flex justify-center">
        <Hint label="Move Right">
          <ArrowRight
            onClick={onMoveRight}
            className="cursor-pointer border border-white/70 size-6 text-white bg-black"
          />
        </Hint>
      </div>
      <div></div>
      <div className="flex justify-center">
        <Hint label="Move down">
          <ArrowDown
            onClick={onMoveDown}
            className="cursor-pointer border border-white/70 size-6 text-white bg-black"
          />
        </Hint>
      </div>
      <div></div>
    </div>
  );
};

interface ZoomControlsProps {
  onZoomIn: () => void;
  onZoomOut: () => void;
}
export const ZoomControls = ({ onZoomIn, onZoomOut }: ZoomControlsProps) => {
  return (
    <div className="flex gap-3 items-center">
      <Hint label="Zoom in" asChild>
        <Plus
          onClick={onZoomIn}
          className="cursor-pointer border border-white/70 size-6 bg-black text-white"
        />
      </Hint>
      <Hint label="Zoom out" asChild>
        <Minus
          onClick={onZoomOut}
          className="cursor-pointer border border-white/70 size-6 bg-black text-white"
        />
      </Hint>
    </div>
  );
};

interface FullscreenControlProps {
  isFullscreen: boolean;
  onToggle: () => void;
}

export const FullscreenControl = ({
  isFullscreen,
  onToggle,
}: FullscreenControlProps) => {
  const Icon = isFullscreen ? Minimize : Maximize;

  const label = isFullscreen ? "Exit fullscreen" : "Enter fullscreen";

  return (
    <div className="flex items-center justify-center gap-4">
      <Hint label={label} asChild>
        <button
          onClick={onToggle}
          className={cn(
            "hover:bg-white/10 border border-white/70",
            isFullscreen ? "bg-black text-white" : "text-black"
          )}
        >
          <Icon className="h-5 w-5" />
        </button>
      </Hint>
    </div>
  );
};
