import { NavigationCard } from "src/components/ui/navigation-card";
export const NavigationCards = () => {
  return (
    <>
      <NavigationCard
        label="Job Applications"
        imageSrc="/application.png"
        href="/applied-posts"
      />
      <NavigationCard
        label="Your books"
        imageSrc="/e-book.png"
        href="/books"
      />
    </>
  );
};
