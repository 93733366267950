import { ReactNode, FC } from "react";
import { Button } from "../ui/button";

interface DesktopSidebarButtonProps {
  children: ReactNode;
  Icon: ReactNode; // Changed from LucideIcon to ReactNode
  route: string;
}

export const DesktopSidebarButton: FC<DesktopSidebarButtonProps> = ({
  children,
  route,
  Icon,
}) => {
  return (
    <Button
      asChild
      variant="outline"
      className={`justify-start bg-inherit hover:bg-primary hover:text-white shadow-none border-0 rounded-2xl ${
        window.location.pathname === route ? "bg-secondary" : ""
      }`}
      size="lg"
    >
      <a href={route}>
        <div className="flex justify-start items-center font-semibold">
          <div
            className={`w-5 h-5 mr-2 group-hover:text-white ${
              window.location.pathname === route ? "" : "#5F6368"
            }`}
          >
            {Icon} {/* Render the Icon as a child element */}
          </div>
          {children}
        </div>
      </a>
    </Button>
  );
};
