import { Button } from "src/components/ui/button";
import { NotificationSheet } from "src/components/ui/notification-sheet";

export const Notifications = () => {
  return (
    <NotificationSheet>
      <Button className="absolute right-0 top-20 origin-bottom-right -translate-y-1/2 -rotate-90 transform rounded-b-none border border-b-0 border-primary bg-white text-lg  font-normal text-black">
        Notifications
      </Button>
    </NotificationSheet>
  );
};
