import { BrandLogo } from "./brand-logo";

export const StickyBranding = () => {
  return (
    <a href="/" className="fixed top-10 left-10">
      <div className="flex items-center gap-x-3">
        <BrandLogo className="size-12" />
        <div className="text-2xl font-extrabold">NHWM</div>
      </div>
    </a>
  );
};
