import { useEffect, useState } from "react";
import { fetchSlots } from "../slot";
import { useFetchAppliedJobs } from "./useFetchAppliedJobs";
import { Slot } from "src/lib/entities";
import { toast } from "sonner";

export const useFetchAllSlots = () => {
  const [slots, setSlots] = useState<Slot[]>([]);
  const [isPending, setIsPending] = useState(true);

  const { appliedJobs } = useFetchAppliedJobs();

  useEffect(() => {
    const cachedSlots = new Map<string, Slot[]>();

    const fetchAllSlots = async () => {
      try {
        if (Array.isArray(appliedJobs) && appliedJobs.length > 0) {
          const cachedData = cachedSlots.get("allAvailableSlots");

          if (cachedData) {
            setSlots(cachedData);
          } else {
            const slotPromises = appliedJobs.map((post) =>
              fetchSlots(post.postApplied, post.baseDistrictOrState),
            );
            const allSlotResults = await Promise.allSettled(slotPromises);

            const successfulSlots = allSlotResults
              .filter((result) => result.status.toLowerCase() === "fulfilled")
              .map((result) => (result as PromiseFulfilledResult<Slot>).value);

            setSlots(successfulSlots.flat());
            cachedSlots.set("allAvailableSlots", successfulSlots);
          }
        }
      } catch (error) {
        toast.error("Something went wrong, please refresh the page!")
        window.location.reload();
        console.error("Failed to fetch slots", error);
      } finally {
        setIsPending(false);
      }
    };

    fetchAllSlots();
  }, [appliedJobs]);

  return { slots, isPending };
};
