import { BrowserRouter } from "react-router-dom";
import { Providers } from "./redux/provider";
import { RouteManager } from "./RouteManager";
import { Toaster } from "sonner";

function App() {
  return (
    <Providers>
      <Toaster richColors position="top-right" />
      <BrowserRouter>
        <RouteManager />
      </BrowserRouter>
    </Providers>
  );
}

export default App;
