import { StickyBranding } from "src/components/ui/sticky-branding";
import { ForgotPasswordForm } from "./forgot-password-form";

export const ForgotPasswordView = () => {
  return (
    <div className="flex justify-center p-3 items-center h-screen flex-col gap-5 text-sm md:text-base">
      <StickyBranding />
      <h3 className="text-2xl font-bold">Forgot Password?</h3>
      <img src="/forgot-password-poster.svg" alt="" className="w-52" />
      <ForgotPasswordForm />
    </div>
  );
};
