import { useEffect, useRef, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { DashboardLayout } from "src/screens/Dashboard/DashboardLayout";
import { MobileSideBar, SheetButtonProps } from "./sidebar";
import { FullScreen, useFullScreenHandle } from "react-full-screen";
import {
  getBookOutline,
  getNextTenPages,
  jumpToPage,
  startReader,
} from "src/redux/api/e-book";
import { AppliedJob, POSTS, Post } from "src/lib/entities";
import { getAppliedJobs } from "src/redux/api/applied-post";
import { CaretLeftIcon, CaretRightIcon } from "@radix-ui/react-icons";
import { Skeleton } from "src/components/ui/skeleton";
import { FullscreenControl, PositionControls, ZoomControls } from "./controls";
import { ReaderPagination } from "./pagination";
import { cn } from "src/lib/utils";

export const ReadBookView = () => {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [searchParams, _] = useSearchParams();
  const navigate = useNavigate();

  const book = searchParams.get("book");
  const part = searchParams.get("part");

  const existingIndex = window.localStorage.getItem(`${book}-${part}`);
  const existingPages = localStorage.getItem(`${book}-${part}`);
  const initialPages = existingPages ? JSON.parse(existingPages) : [];
  const [currentPageIndex, setCurrentPageIndex] = useState(
    Number(existingIndex) || 0,
  );
  const [currentPageUrl, setCurrentPageUrl] = useState("");
  const [pages, setPages] = useState<string[]>(initialPages);
  const [token, setToken] = useState("");
  const [bookOutline, setBookOutline] = useState<SheetButtonProps[]>([]);

  const [isPending, setIsPending] = useState<{
    pageNumber: number;
    isPending: boolean;
  }>();

  const [isPaginationPending, setIsPaginationPending] = useState(false);

  const handle = useFullScreenHandle();

  const readerRef = useRef<HTMLImageElement>(null);

  const getBookOutlineArray = async (bookName: string, part: 1 | 2) => {
    if (part === 1) {
      const commonBookOutline = await getBookOutline("common");
      return commonBookOutline;
    }
    const book = bookName.toLowerCase();
    const bookOutline = await getBookOutline(book);
    return bookOutline;
  };

  useEffect(() => {
    if (!book || !POSTS.includes(book.toUpperCase() as Post)) {
      window.location.href = "/u/book";
      return;
    }

    const preventView = () => {
      readerRef.current?.classList.add("hidden");
    };

    const allowView = () => {
      readerRef.current?.classList.remove("hidden");
    };

    const handleKeyDown = (event: KeyboardEvent) => {
      if (event.metaKey) {
        preventView();
      }
      if (event.shiftKey) {
        preventView();
      }
      if (event.key === "PrintScreen") {
        preventView();
      }
      if (event.key === "EndCall") {
        preventView();
      }
    };

    const handleVisibilityChange = () => {
      if (document.hidden) {
        preventView();
      } else {
        allowView();
      }
    };

    if (document.hidden) {
      preventView();
    }
    const handleKeyUp = () => {
      allowView();
    };

    window.addEventListener("keydown", handleKeyDown);
    window.addEventListener("keyup", handleKeyUp);
    document.addEventListener("visibilitychange", handleVisibilityChange);

    const fetchData = async () => {
      const jobs = await getAppliedJobs();
      const hasJob = !!jobs.find((job: AppliedJob) => job.postApplied === book);
      if (!hasJob) {
        window.location.href = "/u/book";
        return;
      }

      let readerToken;

      readerToken = window.sessionStorage.getItem("readerToken");

      if (!readerToken) {
        const token = crypto.randomUUID();
        window.sessionStorage.setItem("readerToken", token);
      }

      readerToken = window.sessionStorage.getItem("readerToken");
      setToken(readerToken as string);

      if (!part) {
        navigate("/books");
      }

      if (readerToken) {
        const pages = await startReader(
          currentPageIndex,
          book!,
          readerToken,
          Number(part) as 1 | 2,
        );
        setPages(pages?.data.data.pageData);
        setCurrentPageUrl(pages?.data.data.pageData[currentPageIndex]);

        const bookOutline = await getBookOutlineArray(
          book!,
          Number(part) as 1 | 2,
        );
        setBookOutline(bookOutline?.data.data.Outline);
      }
    };

    fetchData();
    return () => {
      window.removeEventListener("keydown", handleKeyDown);
    };
  }, []);

  useEffect(() => {
    localStorage.setItem(
      `${book}-${part}`,
      JSON.stringify({ currentPageIndex, pages }),
    );
    setCurrentPageUrl(pages[currentPageIndex]);
  }, [currentPageIndex]);

  const toggleFullscreen = () => {
    if (handle.active) {
      handle.exit();
    } else if (readerRef?.current) {
      handle.enter();
    }
  };

  const handleNext = async () => {
    setIsPaginationPending(true);
    if (currentPageIndex < pages.length - 1) {
      setCurrentPageIndex(currentPageIndex + 1);
      setIsPaginationPending(false);
      return;
    }
    await getNextTenPages(currentPageIndex, book!, token, Number(part) as 1 | 2)
      .then((nextPageData) => {
        const newPages = nextPageData?.data.data.pageData || [];
        if (newPages.length > 0) {
          setPages([...pages, ...newPages]);
        }
      })
      .finally(() => {
        setIsPaginationPending(false);
      });
  };

  const handlePrev = async () => {
    if (currentPageIndex !== 0) {
      setCurrentPageIndex(currentPageIndex - 1);
    }
  };

  const handleTileClick = (pageNumber: number) => {
    setIsPending({
      pageNumber,
      isPending: true,
    });
    jumpToPage(pageNumber, book!, token, Number(part) as 1 | 2)
      .then((jumpPageData) => {
        const newPages = jumpPageData?.data.data.pageData || [];
        setPages([...newPages]);
        setCurrentPageIndex(pageNumber - 1);
      })
      .finally(() => {
        setIsPending({
          pageNumber,
          isPending: false,
        });
      });
  };

  const [zoomLevel, setZoomLevel] = useState(1);

  useEffect(() => {
    if (!handle.active) {
      setZoomLevel(1);
    }
  }, [handle.active]);

  const zoomIn = () => {
    if (zoomLevel < 2.5) {
      setZoomLevel((prevZoom) => prevZoom + 0.1);
    }
  };

  const zoomOut = () => {
    if (zoomLevel > 1) {
      setZoomLevel((prevZoom) => Math.max(0.1, prevZoom - 0.1));
    }
  };

  const [translateY, setTranslateY] = useState(0);
  const [translateX, setTranslateX] = useState(0);

  useEffect(() => {
    if (!handle.active) {
      setTranslateY(0);
      setTranslateX(0);
    }
  }, [handle.active]);

  const moveUp = () => {
    setTranslateY((prevTranslate) => prevTranslate + 50);
  };

  const moveDown = () => {
    setTranslateY((prevTranslate) => prevTranslate - 50);
  };

  const moveLeft = () => {
    setTranslateX((prevTranslate) => prevTranslate - 50);
  };

  const moveRight = () => {
    setTranslateX((prevTranslate) => prevTranslate + 50);
  };

  return (
    <DashboardLayout>
      <div
        className="relative mx-auto flex max-w-screen-md h-full flex-col gap-4 pt-5 p-3 justify-center"
        onKeyDown={(e) => {
          switch (e.key) {
            case "ArrowLeft":
              handlePrev();
              break;
            case "ArrowRight":
              handleNext();
              break;
          }
        }}
      >
        <div className="absolute -top-2 right-3 md:-right-7 md:top-0">
          <MobileSideBar
            buttons={bookOutline}
            onClick={handleTileClick}
            isPendingPage={isPending}
          />
        </div>
        <FullScreen
          className={cn(
            "relative mx-auto flex h-[80vh] w-full items-center rounded-lg sm:min-w-[500px]",
            !handle.active && "border",
          )}
          handle={handle}
        >
          {handle.active && (
            <CaretLeftIcon
              className="absolute left-0 z-[999] size-11 cursor-pointer bg-black/50 text-white"
              onClick={handlePrev}
            />
          )}
          {currentPageUrl ? (
            <img
              className="pointer-events-none h-full w-full object-contain print:hidden max-h-[1000px]"
              alt="bookpage"
              src={currentPageUrl}
              loading="lazy"
              style={{
                transform: `scale(${zoomLevel}) translateY(${translateY}px) translateX(${translateX}px)`,
              }}
              ref={readerRef}
            />
          ) : (
            <Skeleton className="pointer-events-none h-full w-[90vw] object-contain sm:max-w-xl" />
          )}
          {handle.active && (
            <CaretRightIcon
              className="z-100 absolute right-0 size-11 cursor-pointer bg-black/50 text-white"
              onClick={handleNext}
            />
          )}
          <div className="absolute bottom-2 right-2 flex gap-3">
            {handle.active && (
              <>
                <ZoomControls onZoomIn={zoomIn} onZoomOut={zoomOut} />
                {zoomLevel !== 1 && (
                  <PositionControls
                    onMoveUp={moveUp}
                    onMoveDown={moveDown}
                    onMoveLeft={moveLeft}
                    onMoveRight={moveRight}
                  />
                )}
              </>
            )}
            <FullscreenControl
              isFullscreen={handle.active}
              onToggle={toggleFullscreen}
            />
          </div>
        </FullScreen>
        <ReaderPagination
          onNext={handleNext}
          pending={isPaginationPending}
          onPrev={handlePrev}
        />
      </div>
    </DashboardLayout>
  );
};
