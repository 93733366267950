import { env } from "src/lib/env";
import { axiosAuth } from "./auth";
import axios from "axios";

export const getScheduledExams = async () => {
  const response = await axiosAuth().get(
    `${env.REACT_APP_API_URL}/getscheduledexams`,
  );
  return response.data;
};

export const startExam = async (admitCardNumber: string, uniqueId: string) => {
  try {
    const api = axiosAuth();
    const response = await api.post(`${env.REACT_APP_API_URL}/startexam`, {
      admitCardNumber: admitCardNumber,
      uniqueId: uniqueId,
    });
    return response.data;
  } catch (error) {
    console.error(error);
  }
};

export const validateAdmit = async (admitCardNumber: string) => {
  try {
    const api = axiosAuth();
    const response = await api.post(`${env.REACT_APP_API_URL}/validateadmit`, {
      admitCardNumber: String(admitCardNumber),
    });
    return response.data;
  } catch (error) {
    console.error(error);
  }
};

export const validateAadhar = async (
  aadharNumber: string,
  admitCardNumber: string,
) => {
  try {
    const api = axiosAuth();
    const response = await api.post(`${env.REACT_APP_API_URL}/validateadhar`, {
      adharnumber: String(aadharNumber),
      admitcard: String(admitCardNumber),
    });
    return response.data;
  } catch (error) {
    console.error(error);
  }
};

export const uploadExamPicture = async (
  file: File,
  fileName: string,
  admitCardNumber: string,
  examinationId: string,
) => {
  try {
    const api = axiosAuth();
    const response = await api.post(
      `${env.REACT_APP_API_URL}/getuploadexamphotourl?file=${fileName}`,
      {
        admitCardNumber: admitCardNumber,
        examinationId: examinationId,
      },
    );

    const { url, Key } = response.data.data;

    await axios.put(url, file);
  } catch (error) {
    console.error(error);
  }
};

export const getQuestions = async (
  admitCardNumber: string,
  examinationId: string,
) => {
  try {
    const api = axiosAuth();
    const response = await api.post(`${env.REACT_APP_API_URL}/getquestions`, {
      admitCardNumber: String(admitCardNumber),
      examinationId: String(examinationId),
    });
    return response.data;
  } catch (error) {
    console.error(error);
  }
};

export const getQuestionById = async (id: number) => {
  try {
    const api = axiosAuth();
    const response = await api.get(
      `${env.REACT_APP_API_URL}/getquestionbyid?id=${id}`,
    );

    return response.data;
  } catch (error) {
    console.error(error);
  }
};

export const saveAnswerAPI = async (
  qId: number,
  answer: string,
  uniqueId: string,
) => {
  try {
    const api = axiosAuth();
    const response = await api.post(`${env.REACT_APP_API_URL}/saveanswer`, {
      id: qId,
      answer: answer,
      uniqueId: uniqueId,
    });

    return response.data;
  } catch (error) {
    console.error(error);
  }
};

export const getScheduledExamsForUploadPage = async (token: string) => {
  try {
    const response = await axios.get(
      `${process.env.BASE_API_URL}/getscheduledexams`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      },
    );
    return response.data;
  } catch (error) {
    console.error(error);
  }
};

export const submitExam = async (
  admitCardNumber: string,
  examinationId: string,
) => {
  try {
    const api = axiosAuth();
    const response = await api.post(`${env.REACT_APP_API_URL}/submitexam`, {
      admitCardNumber: String(admitCardNumber),
      examinationId: String(examinationId),
    });
    return response.data;
  } catch (error) {
    console.error(error);
  }
};
