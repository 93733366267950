import * as React from "react";
import { FieldError } from "react-hook-form";

import { cn } from "src/lib/utils";

export interface InputProps
  extends React.InputHTMLAttributes<HTMLInputElement> {}

const Input = React.forwardRef<HTMLInputElement, InputProps>(
  ({ className, type, ...props }, ref) => {
    return (
      <input
        type={type}
        className={cn(
          "flex h-9 w-full rounded-md border border-input bg-transparent px-3 py-1 text-sm shadow-sm transition-colors file:border-0 file:bg-transparent file:text-sm file:font-medium placeholder:text-muted-foreground focus-visible:outline-none focus-visible:ring-1 focus-visible:ring-ring disabled:cursor-not-allowed disabled:opacity-50",
          className
        )}
        ref={ref}
        {...props}
      />
    );
  }
);
Input.displayName = "Input";

export { Input };

interface AnimatedInputProps extends InputProps {
  label: string;
  containerClassName?: string;
  error?: FieldError | boolean;
  inputClassName?: string;
  labelClassName?: string;
}

const FancyInput = React.forwardRef<
  HTMLInputElement,
  AnimatedInputProps
>(
  (
    {
      containerClassName,
      inputClassName,
      labelClassName,
      label,
      error,
      ...inputProps
    },
    ref
  ) => {
    return (
      <div
        className={cn(
          "relative h-12 rounded-lg border bg-white",
          containerClassName,
          error ? "border-[#ff3037] bg-red-500/15" : "border-zinc-200"
        )}
      >
        <input
          id={inputProps.id || label}
          name={inputProps.name}
          type={inputProps.type}
          className={cn(
            "peer h-full w-full rounded-lg bg-transparent px-2 pb-0 pt-[0.9rem] text-sm tracking-wide text-black placeholder-transparent focus:outline-none",
            inputClassName
          )}
          ref={ref}
          placeholder=""
          {...inputProps}
        />
        <label
          htmlFor={inputProps.id || ""}
          className={cn(
            "absolute left-2 top-3 -translate-y-1/2 transform select-none text-xs text-zinc-500 transition-all peer-placeholder-shown:top-1/2 peer-placeholder-shown:text-sm peer-placeholder-shown:text-[#86868b] peer-hover:cursor-text peer-focus:top-3 peer-focus:text-xs peer-focus:text-zinc-500",
            labelClassName,
            error ? "!text-[#ff3037]" : ""
          )}
        >
          {label}
        </label>
      </div>
    );
  }
);
FancyInput.displayName = "FloatingLabel";

export { FancyInput };
