"use client";
import { MobileIcon } from "@radix-ui/react-icons";
import { AlertTriangle } from "lucide-react";
import { ChangeEvent, useEffect, useRef, useState } from "react";
import { FullScreen, useFullScreenHandle } from "react-full-screen";
import QRCode from "react-qr-code";
import { toast, Toaster } from "sonner";
import { Button } from "src/components/ui/button";
import { Checkbox } from "src/components/ui/checkbox";
import { Label } from "src/components/ui/label";
import { RadioGroup, RadioGroupItem } from "src/components/ui/radio-group";
import { ScrollArea } from "src/components/ui/scroll-area";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from "src/components/ui/table";
import { Textarea } from "src/components/ui/textarea";
import {
  useLongAnswerStore,
  useMcqAnswerStore,
} from "src/components/zustand/AnswerStore";
import {
  useDescTimerStore,
  useMcqTimerStore,
} from "src/components/zustand/Timer";
import { Question } from "src/lib/entities";
import {
  getQuestionById,
  getQuestions,
  saveAnswerAPI,
} from "src/redux/api/exam";
import useQueryParams from "src/redux/api/hooks/useQueryParams";
import { Modal } from "react-overlays";
import Backdrop from "src/components/ui/Backdrop";
import SubmitExamDialog from "src/components/ui/submit-exam-dialog";
import { TabContent, TabList, Tabs, TabTrigger } from "src/components/ui/Tabs";
import NextSectionDialog from "src/components/ui/next-section-dialog";
import axios from "axios";
import { env } from "src/lib/env";

export default function ExamPage() {
  const [isFullScreen, setIsFullScreen] = useState(false);
  const [showWarningModal, setShowWarningModal] = useState(true);
  const [showShortAnswerReviewModal, setShowShortAnswerReviewModal] =
    useState(false);
  const [showLongAnswerReviewModal, setShowLongAnswerReviewModal] =
    useState(false);
  const [isFirstVisit, setIsFirstVisit] = useState(true);
  const handle = useFullScreenHandle();
  const [isQRAnswerAvailable, setIsQRAnswerAvailable] = useState<
    boolean | undefined
  >(undefined);
  const [optionalAnswer, setOptionalAnswer] = useState<string | undefined>(
    undefined,
  );
  const [questions, setQuestions] = useState<Question[]>([]);
  const [currentQuestionIndex, setCurrentQuestionIndex] = useState(0);

  const { mcqanswers, saveMcqAnswer, uuid, clearMcqStorage } =
    useMcqAnswerStore();
  const { longanswers, savelongAnswer, uuid2, clearDescStorage } =
    useLongAnswerStore();

  const [descriptiveAnswer, setDescriptiveAnswer] = useState<string | "">("");
  const [descanswers, setDescanswers] = useState(longanswers);

  const [isActive, setIsActive] = useState(true);
  const [warningCount, setWarningCount] = useState(0);
  const maxWarnings = 3;
  const mcqTimerStore = useMcqTimerStore();
  const descTimerStore = useDescTimerStore();
  const [mcqtimer, setmcqTimer] = useState(mcqTimerStore.timer);
  const [desctimer, setdescTimer] = useState(descTimerStore.timer);
  const [answerUrl, setAnswerUrl] = useState<string | undefined>("");
  const [loading, setLoading] = useState(true);
  const token = localStorage.getItem("jtoken");

  const [currentQuestion, setCurrentQuestion] = useState<Question | undefined>(
    undefined,
  );

  const [activeTab, setActiveTab] = useState("mcqTab");

  const handleInputChange = (e: ChangeEvent<HTMLTextAreaElement>) => {
    const newDescAnswers = [...descanswers];
    newDescAnswers[currentQuestionIndex] = {
      ...newDescAnswers[currentQuestionIndex],
      descriptiveAnswer: e.target.value,
    };
    setDescanswers(newDescAnswers);
    setDescriptiveAnswer(e.target.value);
  };

  // separate the questions based on the question type

  const unshuffledOptionalQuestions: Question[] = questions.filter(
    (question) => {
      return question.question.type.toLowerCase() === "optional";
    },
  );

  const unshuffledLongQuestions: Question[] = questions.filter((question) => {
    return question.question.type.toLowerCase() !== "optional";
  });

  // suffle the questions

  function shuffleOptionalQuestions(array: Question[]): Question[] {
    for (let i = array.length - 1; i > 0; i--) {
      const j = Math.floor(Math.random() * (i + 1));
      [array[i], array[j]] = [array[j], array[i]];
    }
    return array;
  }

  function shuffleQuestions(array: Question[]): Question[] {
    for (let i = array.length - 1; i > 0; i--) {
      const j = Math.floor(Math.random() * (i + 1));
      [array[i], array[j]] = [array[j], array[i]];
    }
    return array.slice().sort((a, b) => a.question.marks - b.question.marks);
  }

  const [optionalQuestions, setOptionalQuestions] = useState<Question[]>([]);
  const [longQuestions, setLongQuestions] = useState<Question[]>([]);

  useEffect(() => {
    if (!isFirstVisit) {
      setOptionalQuestions(
        shuffleOptionalQuestions(unshuffledOptionalQuestions),
      );
      setLongQuestions(shuffleQuestions(unshuffledLongQuestions));
      setLoading(false);
    }
  }, [isFirstVisit]);

  // handle refresh of the page.
  const handleRefresh = useRef((event: { returnValue: string }) => {
    const confirmationMessage = "Are you sure you want to leave?";
    const userConfirmed = window.confirm(confirmationMessage);

    if (userConfirmed) {
      window.removeEventListener("beforeunload", handleRefresh);

      mcqTimerStore.clearStore();
      descTimerStore.clearStore();
      clearMcqStorage();
      clearDescStorage();

      window.location.replace(
        `/start/exam/finish?admit=${queryParams.admit}&exam=${queryParams.exam}`,
      );
    }

    event.returnValue = confirmationMessage; // Standard for most browsers
    return confirmationMessage; // For some older browsers
  }).current;

  useEffect(() => {
    const handlePageChange = async () => {
      if (document.hidden) {
        // User switched tabs or left the page
        if (warningCount < maxWarnings) {
          toast.warning(
            `Warning: Do not switch tabs or leave the page. You have ${
              maxWarnings - warningCount
            } warnings remaining.`,
          );
          setWarningCount((prevCount) => prevCount + 1);
        } else {
          // Auto-submit the exam after reaching the maximum warnings
          window.removeEventListener("beforeunload", handleRefresh);

          mcqTimerStore.clearStore();
          descTimerStore.clearStore();
          clearMcqStorage();
          clearDescStorage();

          window.location.replace(
            `/start/exam/finish?admit=${queryParams.admit}&exam=${queryParams.exam}`,
          );
          setIsActive(false);
        }
      }
    };

    document.addEventListener("visibilitychange", handlePageChange);
    window.addEventListener("beforeunload", handleRefresh);

    return () => {
      document.removeEventListener("visibilitychange", handlePageChange);
      window.removeEventListener("beforeunload", handleRefresh);
    };
  }, [isActive, warningCount]);

  useEffect(() => {
    if (!isFirstVisit && activeTab === "mcqTab") {
      if (mcqTimerStore.timer === 0) {
        handleSectionChange();
        return;
      }
      if (mcqTimerStore.timer === 1500) {
        toast.warning("You have so much time remaining");
      }
      if (mcqTimerStore.timer === 900) {
        toast.warning("You have 15 minutes remaining");
      }
      if (mcqTimerStore.timer === 300) {
        toast.warning("You have 5 minutes remaining");
      }
      if (mcqTimerStore.timer === 120) {
        toast.error("You have 2 minutes remaining");
      }
    }
  }, [isFirstVisit]);

  useEffect(() => {
    if (!isFirstVisit && activeTab === "descTab") {
      if (descTimerStore.timer === 0) {
        handleExamSubmit();
        return;
      }
      if (descTimerStore.timer === 2400) {
        toast.warning("You have 40 minutes remaining");
      }
      if (descTimerStore.timer === 900) {
        toast.warning("You have 15 minutes remaining");
      }
      if (descTimerStore.timer === 300) {
        toast.warning("You have 5 minutes remaining");
      }
      if (descTimerStore.timer === 120) {
        toast.error("You have 2 minutes remaining");
      }
    }
  }, [isFirstVisit, activeTab]);

  const formatTimer = (seconds: number) => {
    const hours = Math.floor(seconds / 3600);
    const minutes = Math.floor((seconds % 3600) / 60);
    const remainingSeconds = seconds % 60;
    return `${String(hours).padStart(2, "0")}:${String(minutes).padStart(
      2,
      "0",
    )}:${String(remainingSeconds).padStart(2, "0")}`;
  };

  const clearLocalStateAnswers = () => {
    setOptionalAnswer("");
    setDescriptiveAnswer("");
    setAnswerUrl("");
    setIsQRAnswerAvailable(undefined);
  };

  document.addEventListener("contextmenu", (event) => {
    event.preventDefault();
  });

  const handleSaveAndNextmcq = async () => {
    if (currentQuestionIndex > optionalQuestions.length - 1) {
      return;
    }

    try {
      await saveAnswerAPI(
        optionalQuestions[currentQuestionIndex].id,
        optionalQuestions[currentQuestionIndex].question.type.toLowerCase() ===
          "optional"
          ? optionalAnswer || ""
          : descriptiveAnswer || "",
        uuid,
      );
      toast.success("Answer saved successfully");
    } catch (error) {
      if (axios.isAxiosError(error)) {
        if (error.response && error.response.status === 403) {
          toast.error("Sorry, Your Exam is Terminated!");
          window.location.replace(
            `/start/exam/finish?admit=${queryParams.admit}&exam=${queryParams.exam}`,
          );
        } else {
          toast.error("Something went wrong while saving your answer!");
        }
      } else {
        toast.error("Something went wrong!");
        console.error("An unexpected error occured:", error);
      }
    }

    saveMcqAnswer(currentQuestionIndex, {
      optionalAnswer: optionalAnswer,
    });

    clearLocalStateAnswers();

    if (currentQuestionIndex !== optionalQuestions.length - 1) {
      setCurrentQuestionIndex((prevIndex) => prevIndex + 1);
    }
  };

  const handleSaveAndNextdesc = async () => {
    if (currentQuestionIndex > longQuestions.length - 1) {
      return;
    }

    try {
      await saveAnswerAPI(
        longQuestions[currentQuestionIndex].id,
        longQuestions[currentQuestionIndex].question.type.toLowerCase() ===
          "optional"
          ? optionalAnswer || ""
          : descriptiveAnswer || "",
        uuid,
      );
      toast.success("Answer saved successfully");
    } catch (error) {
      if (axios.isAxiosError(error)) {
        if (error.response && error.response.status === 403) {
          toast.error("Sorry, Your Exam is Terminated!");
          window.location.replace(
            `/start/exam/finish?admit=${queryParams.admit}&exam=${queryParams.exam}`,
          );
        } else {
          toast.error("Something went wrong while saving your answer!");
        }
      } else {
        toast.error("Something went wrong!");
        console.error("An unexpected error occured:", error);
      }
    }

    savelongAnswer(currentQuestionIndex, {
      descriptiveAnswer: descriptiveAnswer,
      answerUrl: answerUrl,
    });

    const response = await getQuestionById(
      longQuestions[currentQuestionIndex].id,
    );
    if (response) {
      if (response?.data.question.answerurl) {
        const uploadedAnswerurl = response?.data.question.answerurl;

        setAnswerUrl(uploadedAnswerurl);

        savelongAnswer(currentQuestionIndex, {
          descriptiveAnswer: descriptiveAnswer,
          answerUrl: uploadedAnswerurl,
        });
      }
    }

    clearLocalStateAnswers();

    if (currentQuestionIndex !== longQuestions.length - 1) {
      setCurrentQuestionIndex((prevIndex) => prevIndex + 1);
    }
  };

  const handleSkip = () => {
    if (currentQuestionIndex >= questions.length - 1) {
      return;
    }
    clearLocalStateAnswers();

    toast.success("Skipped");

    if (currentQuestionIndex !== questions.length - 1) {
      setCurrentQuestionIndex((prevIndex) => prevIndex + 1);
    }
  };

  const handlePrevious = () => {
    if (currentQuestionIndex === 0) {
      return;
    }
    setCurrentQuestionIndex((prevIndex) => prevIndex - 1);
  };

  const handleExamSubmit = async () => {
    await handleSaveAndNextdesc();
    descTimerStore.stopTimer();

    window.removeEventListener("beforeunload", handleRefresh);

    mcqTimerStore.clearStore();
    descTimerStore.clearStore();
    clearMcqStorage();
    clearDescStorage();

    window.location.replace(
      `/start/exam/finish?admit=${queryParams.admit}&exam=${queryParams.exam}`,
    );
  };

  const enterFullScreen = () => {
    handle.enter();
    setShowWarningModal(false);
  };

  useEffect(() => {
    setIsFullScreen(handle.active);
    openWarningModal();
  }, [handle.active]);

  const openWarningModal = () => {
    if (!isFullScreen && !isFirstVisit) {
      setShowWarningModal(true);
    }
  };

  const queryParams = useQueryParams(["admit", "exam"]);

  // fetching data

  useEffect(() => {
    const fetchData = async () => {
      try {
        const { admit, exam } = queryParams;

        // Validate if admit and exam are present before making the request
        if (admit && exam) {
          const data = await getQuestions(admit, exam);
          setQuestions(data.data.examination);
          setIsQRAnswerAvailable(true);
        } else {
          setIsQRAnswerAvailable(false);
          // Handle the case when admit or exam is missing
          console.error("Admit or exam parameter is missing");
        }
      } catch (error) {
        console.error(error);
      }
    };

    fetchData();
  }, []);

  const handleSectionChange = () => {
    handleSaveAndNextmcq();
    mcqTimerStore.stopTimer();
    setCurrentQuestionIndex(0);
    setActiveTab("descTab");
    enterFullScreen();
    descTimerStore.startTimer();
  };
  // handles the automatic section change
  const sectionChange = () => {
    handleSaveAndNextmcq();
    mcqTimerStore.stopTimer();
    setCurrentQuestionIndex(0);
    setActiveTab("descTab");
    descTimerStore.startTimer();
  };

  // handles first section timer end.
  const handleMcqSectionTimerEnd = () => {
    sectionChange();
  };
  // Callback function to be called when the timer ends in the first section.

  useEffect(() => {
    mcqTimerStore.onTimerEnd = handleMcqSectionTimerEnd;

    // Clean up the callback function when the component is unmounted
    return () => {
      mcqTimerStore.onTimerEnd = () => {};
    };
  }, [mcqTimerStore]);

  // handles second section timer end.
  // callback function to be called when the timer ends in the second section.
  const handleDescTimerEnd = () => {
    handleExamSubmit();
  };

  useEffect(() => {
    descTimerStore.onTimerEnd = handleDescTimerEnd;

    return () => {
      descTimerStore.onTimerEnd = () => {};
    };
  }, [descTimerStore]);

  async function handleNavigation(index: number): Promise<void> {
    // const descriptiveAnswerVar = descriptiveAnswer;
    // const optionalAnswerVar = optionalAnswer;
    // const answerUrlVar = answerUrl;
    // clearLocalStateAnswers();
    // await saveAnswerAPI(
    //   questions[currentQuestionIndex].id,
    //   questions[currentQuestionIndex].question.type === "OPTIONAL"
    //     ? optionalAnswer || ""
    //     : descriptiveAnswerVar || "",
    //   uuid,
    // ).then(() => {
    //   saveAnswer(currentQuestionIndex, {
    //     descriptiveAnswer: descriptiveAnswerVar,
    //     optionalAnswer: optionalAnswerVar,
    //     answerUrl: answerUrlVar,
    //   });
    //   setCurrentQuestionIndex(index);
    // });
    // const { admit, exam } = queryParams;
    // const data = await getQuestions(admit!, exam!);
    // setQuestions(data.data.examination);
    clearLocalStateAnswers();
    setCurrentQuestionIndex(index);
  }

  const textAreaRef = useRef<HTMLTextAreaElement | null>(null);

  useEffect(() => {
    if (textAreaRef.current) {
      textAreaRef.current.focus();
    }
  }, [currentQuestionIndex]);

  return (
    <div>
      <FullScreen
        className="container mx-auto h-screen bg-white px-4 py-10"
        handle={handle}
      >
        {/* <ShadCNToaster /> */}
        <Toaster position="top-center" richColors className="z-[9999999]" />
        <Tabs
          defaultValue="mcqTab"
          value={activeTab}
          onValueChange={setActiveTab}
        >
          <TabList
            className="border-mauve6 flex justify-end border-b"
            loop={false}
          >
            <TabTrigger
              className="text-mauve11 hover:text-violet11 flex h-[40px] w-[111px] cursor-pointer select-none items-center justify-center bg-white text-lg leading-none text-black outline-none first:rounded-tl-md last:rounded-tr-md data-[state=active]:bg-slate-300 data-[state=active]:shadow-[inset_0_-1px_0_0,0_1px_0_0] data-[state=active]:shadow-current data-[state=active]:focus:relative data-[state=active]:focus:shadow-[0_0_0_2px] data-[state=active]:focus:shadow-primary"
              value="mcqTab"
              disabled={activeTab === "descTab"}
            >
              Section 1
            </TabTrigger>
            <TabTrigger
              className="text-mauve11 hover:text-violet11 flex h-[40px] w-[111px] cursor-default select-none items-center justify-center bg-white text-lg leading-none text-black outline-none first:rounded-tl-md last:rounded-tr-md data-[state=active]:bg-slate-300 data-[state=active]:shadow-[inset_0_-1px_0_0,0_1px_0_0] data-[state=active]:shadow-current data-[state=active]:focus:relative data-[state=active]:focus:shadow-[0_0_0_2px] data-[state=active]:focus:shadow-primary"
              value="descTab"
              disabled={activeTab === "mcqTab"}
            >
              Section 2
            </TabTrigger>
          </TabList>
          <TabContent value="mcqTab">
            {activeTab === "mcqTab" && (
              <div className="grid grid-cols-4 gap-10">
                <div className="col-span-3">
                  <div className="mb-6">
                    <div className="flex justify-start">
                      <div className="rounded-md bg-gray-200 px-4 py-2">
                        <span className="font-mono text-lg">
                          {formatTimer(mcqTimerStore.timer)}
                        </span>
                      </div>
                    </div>
                  </div>
                  <div className="mb-10">
                    <h1 className="text-2xl font-semibold">
                      {loading
                        ? "Loading..."
                        : `${currentQuestionIndex + 1}. ${
                            optionalQuestions[currentQuestionIndex]?.question
                              .question
                          }`}
                    </h1>
                  </div>
                  {loading ? (
                    <div>Loading...</div>
                  ) : (
                    optionalQuestions[currentQuestionIndex]?.question.type ===
                      "OPTIONAL" && (
                      <RadioGroup className="grid h-4/5 grid-cols-1 grid-rows-4 gap-4">
                        {Array.from({ length: 4 }, (_, index) => index + 1).map(
                          (optionNumber) => {
                            const optionKey = `option${optionNumber}`;
                            const optionId = `option${optionNumber}`;
                            const optionValue = (
                              optionalQuestions[currentQuestionIndex]
                                ?.question as any
                            )[optionKey];

                            return (
                              <div
                                key={optionKey}
                                className={`flex items-center justify-start rounded-md bg-gray-200 hover:cursor-pointer hover:bg-gray-300 ${
                                  optionalAnswer === optionValue
                                    ? "bg-gray-400"
                                    : ""
                                }`}
                                onClick={() => setOptionalAnswer(optionValue)}
                              >
                                {/* if answer is available in the question object then check it.*/}
                                <RadioGroupItem
                                  value={optionValue}
                                  id={optionId}
                                  checked={
                                    optionalAnswer === optionValue ||
                                    mcqanswers[currentQuestionIndex]
                                      ?.optionalAnswer === optionValue
                                  }
                                  className="ml-5"
                                  onChange={() =>
                                    setOptionalAnswer(optionValue)
                                  }
                                />
                                <Label className="ml-2" htmlFor={optionId}>
                                  {optionValue}
                                </Label>
                              </div>
                            );
                          },
                        )}
                      </RadioGroup>
                    )
                  )}
                  {currentQuestionIndex !== 0 && (
                    <Button
                      className="fixed bottom-5 left-5 w-64 p-5"
                      onClick={handlePrevious}
                    >
                      Previous
                    </Button>
                  )}
                </div>
                <div className="col-span-1">
                  <ScrollArea className="mt-16 h-[60vh] grow">
                    <ul className="space-y-2 overflow-y-auto">
                      {optionalQuestions.map((_, index) => (
                        <li
                          key={index}
                          className={`${currentQuestionIndex === index ? "font-semibold text-primary" : ""} flex flex-row items-center rounded-md px-1 hover:bg-secondary`}
                        >
                          <Checkbox
                            id={`Question ${index + 1}`}
                            checked={!!mcqanswers[index]?.optionalAnswer}
                          />
                          <span className="mx-1">{`Q.${index + 1}`}</span>
                          <div
                            className="ml-1 flex min-h-10 cursor-pointer items-center justify-start text-nowrap p-1 pr-2"
                            onClick={() => handleNavigation(index)}
                          >
                            {optionalQuestions[index].question.question.length >
                            35
                              ? `${optionalQuestions[index].question.question.slice(0, 35)}...`
                              : `${optionalQuestions[index].question.question}`}
                          </div>
                        </li>
                      ))}
                    </ul>
                  </ScrollArea>

                  <div className="fixed bottom-5 right-5 space-x-3 space-y-3">
                    {currentQuestionIndex !== optionalQuestions.length - 1 && (
                      <Button
                        className={`w-32 p-5 ${
                          (!!currentQuestion?.answerUrl ||
                            !!descriptiveAnswer ||
                            !!optionalAnswer) &&
                          "hidden"
                        }`}
                        onClick={handleSkip}
                      >
                        Skip
                      </Button>
                    )}
                    {currentQuestionIndex !== optionalQuestions.length - 1 ? (
                      <Button
                        className="w-56 p-5"
                        onClick={handleSaveAndNextmcq}
                      >
                        Save and Next
                      </Button>
                    ) : (
                      <Button
                        className="w-64 p-5"
                        onClick={() => {
                          handle.exit();
                          setShowWarningModal(false);
                          handleSaveAndNextmcq();
                          setShowShortAnswerReviewModal(true);
                        }}
                      >
                        Save and Review
                      </Button>
                    )}
                  </div>
                </div>
              </div>
            )}
          </TabContent>
          <TabContent value="descTab">
            {activeTab === "descTab" && (
              <div className="grid grid-cols-4 gap-10">
                <div className="col-span-3">
                  <div className="mb-6">
                    <div className="flex justify-start">
                      <div className="rounded-md bg-gray-200 px-4 py-2">
                        <span className="font-mono text-lg">
                          {formatTimer(descTimerStore.timer)}
                        </span>
                      </div>
                    </div>
                  </div>
                  <div className="mb-10">
                    {loading ? (
                      <p>Loading...</p>
                    ) : (
                      <div>
                        <h1 className="text-2xl font-semibold">
                          {`${currentQuestionIndex + 1}. ${
                            longQuestions[currentQuestionIndex]?.question
                              .question
                          }`}
                        </h1>
                        <h2 className="text-lg font-semibold text-red-500">
                          {longQuestions[currentQuestionIndex]?.question
                            .type === "SHORT"
                            ? "(Short Answer in maximum one sentence)"
                            : "(Descriptive Answer in maximum two paragraph)"}
                        </h2>
                      </div>
                    )}
                  </div>
                  {loading ? (
                    <div>Loading...</div>
                  ) : longQuestions[currentQuestionIndex]?.question.type ===
                    "SHORT" ? (
                    <div>
                      <Textarea
                        className="mb-4 h-[35vh] w-full resize-none border p-4"
                        placeholder="Type your answer here..."
                        ref={textAreaRef}
                        maxLength={300}
                        value={
                          descanswers[currentQuestionIndex]
                            ?.descriptiveAnswer || ""
                        }
                        onChange={handleInputChange}
                        onPaste={(e) => {
                          e.preventDefault();
                          toast.warning("Pasting is not allowed!");
                        }}
                      />
                    </div>
                  ) : (
                    <div>
                      <Textarea
                        className="mb-4 h-[45vh] w-full resize-none border p-4"
                        placeholder="Type your answer here..."
                        ref={textAreaRef}
                        value={
                          descanswers[currentQuestionIndex]
                            ?.descriptiveAnswer || ""
                        }
                        onChange={handleInputChange}
                        onPaste={(e) => {
                          e.preventDefault();
                          toast.warning("Pasting is not allowed!");
                        }}
                      />
                      <div className="sticky bottom-3">
                        <div className="flex items-center justify-between">
                          <div className="flex items-center space-x-2">
                            <p className="text-lg">OR</p>
                            <MobileIcon className="h-6 w-6 text-green-500" />
                            <p className="text-lg">
                              Upload handwritten answer from mobile by scanning
                              the QR and after successfully uploading the answer
                              in mobile click save and next here.
                            </p>
                          </div>
                          <QRCode
                            value={`${env.REACT_APP_FRONTEND_URL}/upload-answer?id=${
                              longQuestions[currentQuestionIndex].id
                            }&examId=${
                              longQuestions[currentQuestionIndex].examinationId
                            }&questionNumber=${
                              longQuestions[currentQuestionIndex]
                                ?.questionNumber
                            }&token=${token}&admit=${
                              queryParams.admit
                            }&uuid=${uuid}`}
                            className="h-36 w-36"
                          />
                        </div>
                      </div>
                    </div>
                  )}
                  {currentQuestionIndex !== 0 && (
                    <Button
                      className="fixed bottom-5 left-5 w-64 p-5"
                      onClick={handlePrevious}
                    >
                      Previous
                    </Button>
                  )}
                </div>

                <div className="col-span-1">
                  <ScrollArea className="mt-16 h-[60vh]">
                    <ul className="space-y-2 overflow-y-auto">
                      {longQuestions.map((_, index) => (
                        <li
                          key={index}
                          className={`${currentQuestionIndex === index ? "font-semibold text-primary" : ""} flex flex-row items-center rounded-md hover:bg-secondary`}
                        >
                          <Checkbox
                            id={`Question ${index + 1}`}
                            checked={
                              !!longanswers[index]?.answerUrl ||
                              !!longanswers[index]?.descriptiveAnswer
                            }
                          />
                          <span className="mx-1">{`Q.${index + 1}`}</span>
                          <div
                            className="flex min-h-10 cursor-pointer items-center justify-start text-nowrap px-1 py-2 pr-2"
                            onClick={() => handleNavigation(index)}
                          >
                            {longQuestions[index].question.question.length > 35
                              ? `${longQuestions[index].question.question.slice(0, 35)}...`
                              : `${longQuestions[index].question.question}`}
                          </div>
                        </li>
                      ))}
                    </ul>
                  </ScrollArea>

                  <div className="fixed bottom-5 right-5 space-x-3 space-y-3">
                    {currentQuestionIndex !== longQuestions.length - 1 && (
                      <Button
                        className={`w-32 p-5 ${
                          (!!currentQuestion?.answerUrl ||
                            !!descriptiveAnswer ||
                            !!optionalAnswer) &&
                          "hidden"
                        }`}
                        onClick={handleSkip}
                      >
                        Skip
                      </Button>
                    )}
                    {currentQuestionIndex !== longQuestions.length - 1 ? (
                      <Button
                        className="w-56 p-5"
                        onClick={handleSaveAndNextdesc}
                      >
                        Save and Next
                      </Button>
                    ) : (
                      <Button
                        className="w-64 p-5"
                        onClick={() => {
                          handle.exit();
                          setShowWarningModal(false);
                          handleSaveAndNextdesc();
                          setShowLongAnswerReviewModal(true);
                        }}
                      >
                        Save and Review
                      </Button>
                    )}
                  </div>
                </div>
              </div>
            )}
          </TabContent>
        </Tabs>
      </FullScreen>
      <Modal
        show={showShortAnswerReviewModal}
        onHide={() => setShowShortAnswerReviewModal(false)}
        onEscapeKeyDown={(e: KeyboardEvent) => e.preventDefault()}
        backdrop={true}
        renderBackdrop={(props) => <Backdrop {...props} />}
        aria-labelledby="start exam warning!"
        style={{
          borderRadius: "8px",
          border: "none",
          boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
          background: "#e0daed",
          width: "60%",
          padding: "20px",
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          zIndex: 1040,
        }}
      >
        <div>
          <ScrollArea className="h-[75vh] ">
            <Table>
              <TableHeader>
                <TableRow>
                  <TableHead className="w-48">Questions</TableHead>
                  <TableHead className="pr-4 text-right">Status</TableHead>
                </TableRow>
              </TableHeader>
              <TableBody>
                {optionalQuestions.map((question, index) => (
                  <TableRow key={question.id}>
                    <TableCell className="w-[80%] truncate text-wrap font-medium">
                      {`${index + 1}.${question.question.question}`}
                    </TableCell>
                    <TableCell className="pr-4 text-right">
                      {!!mcqanswers[index]?.optionalAnswer
                        ? "Attempted"
                        : "Not Attempted"}
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </ScrollArea>
          <div className="flex w-full justify-end gap-2">
            <Button
              onClick={() => {
                handle.enter();
                setShowShortAnswerReviewModal(false);
              }}
            >
              Go back
            </Button>
            <NextSectionDialog onClick={handleSectionChange}>
              <Button className="font-medium text-white">
                Go to Next Section
              </Button>
            </NextSectionDialog>
          </div>
        </div>
      </Modal>
      <Modal
        show={showLongAnswerReviewModal}
        onHide={() => setShowLongAnswerReviewModal(false)}
        onEscapeKeyDown={(e: KeyboardEvent) => e.preventDefault()}
        backdrop={true}
        renderBackdrop={(props) => <Backdrop {...props} />}
        aria-labelledby="start exam warning!"
        style={{
          borderRadius: "8px",
          border: "none",
          boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
          background: "#e0daed",
          width: "60%",
          padding: "20px",
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          zIndex: 1040,
        }}
      >
        <div>
          <ScrollArea className="h-[75vh] ">
            <Table>
              <TableHeader>
                <TableRow>
                  <TableHead className="w-48">Questions</TableHead>
                  <TableHead className="pr-4 text-right">Status</TableHead>
                </TableRow>
              </TableHeader>
              <TableBody>
                {longQuestions.map((question, index) => (
                  <TableRow key={question.id}>
                    <TableCell className="w-[80%] truncate text-wrap font-medium">
                      {`${index + 1}.${question.question.question}`}
                    </TableCell>
                    <TableCell className="pr-4 text-right">
                      {!!longanswers[index]?.descriptiveAnswer ||
                      !!longanswers[index]?.answerUrl
                        ? "Attempted"
                        : "Not Attempted"}
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </ScrollArea>
          <div className="flex w-full justify-end gap-2">
            <Button
              onClick={() => {
                handle.enter();
                setShowLongAnswerReviewModal(false);
              }}
            >
              Go back
            </Button>
            <SubmitExamDialog onClick={handleExamSubmit}>
              <Button className="font-medium text-white">Submit</Button>
            </SubmitExamDialog>
          </div>
        </div>
      </Modal>
      <Modal
        show={showWarningModal}
        onHide={() => setShowWarningModal(false)}
        onEscapeKeyDown={(e: KeyboardEvent) => e.preventDefault()}
        backdrop={true}
        renderBackdrop={(props) => <Backdrop {...props} />}
        aria-labelledby="start exam warning!"
        style={{
          border: "none",
          boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
          background: "#e0daed",
          width: "60%",
          padding: "20px",
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          zIndex: 1040,
        }}
      >
        <div className="flex flex-col gap-5">
          <div className="mb-4 text-center text-2xl font-bold text-red-600">
            {isFirstVisit ? "Start the exam" : "Warning"}
          </div>
          {isFirstVisit ? (
            <>
              <div className="mb-4 flex flex-col items-center justify-center text-center">
                <p className="text-xl font-semibold text-red-600">
                  Please do not refresh or change tabs for the duration of the
                  exam
                </p>
                <p>
                  Any attempt to leave the screen will result in a submission of
                  the exam.
                </p>
              </div>
              <Button
                onClick={() => {
                  setShowWarningModal(false);
                  setIsFirstVisit(false);
                  enterFullScreen();
                  mcqTimerStore.startTimer();
                }}
              >
                Okay
              </Button>
            </>
          ) : (
            <div className="flex flex-col items-center gap-8">
              <div className="flex flex-col items-center justify-center text-red-600">
                <AlertTriangle size="32" />
                <p>You are not in fullscreen mode.</p>
                <p>
                  Any attempt to leave the screen will result in a submission of
                  the exam.
                </p>
              </div>
              <Button onClick={enterFullScreen}>Go Fullscreen</Button>
            </div>
          )}
        </div>
      </Modal>
    </div>
  );
}
