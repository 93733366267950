import { Button } from "src/components/ui/button";
import { cn } from "src/lib/utils";
import { LucideIcon } from "lucide-react";

interface MobileNavButtonProps {
  route: string;
  label: string;
  Icon: LucideIcon;
}

export const MobileNavButton = ({
  route,
  label,
  Icon,
}: MobileNavButtonProps) => {
  return (
    <Button
      asChild
      size="lg"
      variant="outline"
      className={cn(
        "w-full justify-start",
        window.location.pathname === route ? "bg-secondary" : ""
      )}
    >
      <a href={route}>
        <div className="flex justify-start items-center font-normal">
          <Icon
            className={`w-5 h-5 mr-2 group-hover:text-white ${
              window.location.pathname === route ? "" : "text-zinc-400"
            }`}
          />
          {label}
        </div>
      </a>
    </Button>
  );
};
