"use client";

import { Button } from "./button";
import { Loader2 } from "lucide-react";

interface SubmitButtonProps
  extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  isPending: boolean;
  children: React.ReactNode;
}

export const SubmitButton = ({
  children,
  isPending,
  ...props
}: SubmitButtonProps) => {
  
  if (isPending)
    return (
      <Button disabled={isPending} {...props}>
        {children}
        {isPending && <Loader2 className="ml-3 animate-spin" />}
      </Button>
    );

  return (
    <Button disabled={isPending} {...props} type="submit">
      {children}
    </Button>
  );
};
