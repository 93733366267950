export const TermsView = () => {
  return (
    <div className="flex min-h-screen flex-col justify-between">
      <div />
      <div className="w-full py-6">
        <div className="container px-4 md:px-6">
          <div className="flex flex-col space-y-4">
            <div className="space-y-2">
              <h1 className="text-3xl font-bold tracking-tighter sm:text-4xl">
                Welcome to NHWM
              </h1>
            </div>
            <div className="space-y-4">
              <p className="text-gray-500 dark:text-gray-400">
                By accessing or using our exam portal, you agree to comply with
                and be bound by the following terms and conditions of use.
                Please read these terms carefully before accessing or using our
                services.
              </p>
              <dl className="space-y-10">
                <div>
                  <dt className="font-semibold">1. Login Credentials</dt>
                  <dd className="prose max-w-none">
                    You can access the exam portal using your login credentials.
                  </dd>
                </div>
                <div>
                  <dt className="font-semibold">2. Exam Participation</dt>
                  <dd className="prose max-w-none">
                    You have the opportunity to appear for the exam using the
                    provided login credentials.
                  </dd>
                </div>
                <div>
                  <dt className="font-semibold">3. Exam Scheduling</dt>
                  <dd className="prose max-w-none">
                    You have the convenient and flexible option available to
                    schedule your upcoming examination through this platform.
                  </dd>
                </div>
                <div>
                  <dt className="font-semibold">4. E-Books Access</dt>
                  <dd className="prose max-w-none">
                    You can access and read e-books here.
                  </dd>
                </div>
                <div>
                  <dt className="font-semibold">
                    5. Organizational Information
                  </dt>
                  <dd className="prose max-w-none">
                    You have the comprehensive capability to access and obtain
                    all pertinent organizational information.
                  </dd>
                </div>
                <div>
                  <dt className="font-semibold">6. Privacy Policy</dt>
                  <dd className="prose max-w-none">
                    Our privacy policy governs the collection, use, and
                    protection of your personal information. By using NHWM, you
                    consent to the terms of our privacy policy.
                  </dd>
                </div>
              </dl>
              <br />
              <p className="text-gray-500 dark:text-gray-400">
                By using NHWM, you acknowledge that you have read, understood,
                and agree to be bound by these terms and conditions.
              </p>
            </div>
          </div>
        </div>
      </div>
      <footer className="w-full border-t">
        <div className="container flex flex-col items-center justify-center space-y-2 px-4 py-6 text-center text-sm md:px-6">
          <span className="text-gray-500 dark:text-gray-400">
            © 2023 NHWM. All rights reserved.
          </span>
        </div>
      </footer>
    </div>
  );
};
