import { DesktopSidebar } from "src/components/desktop/desktop-sidebar";
import { MobileHeader } from "src/components/mobile/mobile-header";
import { cn } from "src/lib/utils";

interface DashboardLayoutProps {
  children: React.ReactNode;
  className?: string;
}
export const DashboardLayout = ({ children, className }: DashboardLayoutProps) => {
  return (
    <div className={cn("flex flex-col gap-3 lg:flex-row h-[100dvh]", className)}>
      <div>
        <MobileHeader />
        <DesktopSidebar />
      </div>
      {children}
    </div>
  );
};
