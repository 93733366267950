import { env } from "src/lib/env";
import { axiosAuth } from "./auth";
import { z } from "zod";
import { EditProfileSchema } from "src/schemas/edit-profile";
import axios from "axios";

export const fetchCandidate = async () => {
  const response = await axiosAuth().get(
    `${env.REACT_APP_API_URL}/getcandidate`,
  );
  return response.data.data.candidate;
};

export const updateProfilePictureService = async (file: File) => {
  const response = await axiosAuth().get(
    `${env.REACT_APP_API_URL}/getupdateprofilepicurl?file=${file.name}`,
  );

  const { url, Key } = response.data.data;

  await axios.put(url, file);

  return Key;
};

export const uploadSignature = async (file: File) => {
  const response = await axiosAuth().get(
    `${env.REACT_APP_API_URL}/get-sign-url?file=${file.name}`,
  );

  const { url, key } = response.data.data;

  await axios.put(url, file);

  return key;
};

export const editProfile = async (
  values: z.infer<typeof EditProfileSchema>,
) => {
  const payload = {
    adharNumber: values.aadharNumber,
    address: values.address,
    callingMobileNo: values.phoneNumber,
  };

  if (values.profilePicture) {
    await updateProfilePictureService(values.profilePicture);
  }

  await axiosAuth().patch(`${env.REACT_APP_API_URL}/editprofile`, payload);

  return { success: "Details updated" };
};
