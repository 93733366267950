import { useEffect } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { useFetchAppliedJobs } from "src/redux/api/hooks/useFetchAppliedJobs";
import { DashboardLayout } from "src/screens/Dashboard/DashboardLayout";
import { OrderBookForm } from "./order-book-form";
import { AppliedJob, FetchedEbook, Post } from "src/lib/entities";

export const OrderBookView = () => {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [searchParams, _] = useSearchParams();
  const navigate = useNavigate();
  const book = searchParams.get("book");
  const { appliedJobs, isPending } = useFetchAppliedJobs();

  const getJobTitle = (postDetails: string) => {
    const details = postDetails.split(" ");
    if (details.length > 1) {
      return details[0];
    }
    return postDetails;
  };

  useEffect(() => {
    if (!book) {
      navigate("/books");
    }
    if (
      !isPending &&
      !!!appliedJobs.find(
        (job) => getJobTitle(job.postApplied).toLocaleLowerCase() === book,
      )
    ) {
      navigate("/");
    }
  }, [appliedJobs, book, isPending, navigate]);
  return (
    <DashboardLayout>
      <main className="flex flex-grow items-center justify-center">
        <OrderBookForm book={book as Post} />
      </main>
    </DashboardLayout>
  );
};
