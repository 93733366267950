import { useForm } from "react-hook-form";
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from "src/components/ui/form";
import { zodResolver } from "@hookform/resolvers/zod";
import { FancyInput } from "src/components/ui/input";
import { ResetPasswordFormSchema } from "src/schemas/auth";
import { z } from "zod";
import { useNavigate, useSearchParams } from "react-router-dom";
import { SubmitButton } from "src/components/ui/submit-button";
import { ResetPassword } from "src/redux/api/auth";
import { AxiosError } from "axios";
import { PasswordToggleWrapper } from "src/components/ui/password-toggle-wrapper";
import { toast } from "sonner";
import { useState } from "react";

export const ResetPasswordForm = () => {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [searchParams, _] = useSearchParams();

  const navigate = useNavigate();

  const token = searchParams.get("token");
  const email = decodeURIComponent(searchParams.get("email") || "");

  if (!token || !email) {
    navigate("/forgot");
  }

  const form = useForm<z.infer<typeof ResetPasswordFormSchema>>({
    resolver: zodResolver(ResetPasswordFormSchema),
    defaultValues: {
      password: "",
      confirmPassword: "",
    },
  });

  const [isPending, setIsPending] = useState(false);

  const onSubmit = (values: z.infer<typeof ResetPasswordFormSchema>) => {
    setIsPending(true);
    ResetPassword(values, token!, email!)
      .then((status) => {
        if (status === 200) {
          toast.success("Password Changed Successfully");
          navigate("/sign-in");
        }
      })
      .catch((error) => {
        if (error instanceof AxiosError) {
          form.setError("root", {
            message: error.response?.data.message,
          });
        }
      })
      .finally(() => {
        setIsPending(false);
      });
  };

  return (
    <Form {...form}>
      <form
        onSubmit={form.handleSubmit(onSubmit)}
        className="flex w-full max-w-[450px] flex-col gap-3 p-3"
      >
        <FormField
          control={form.control}
          name="password"
          render={({ field }) => (
            <FormItem>
              <FormLabel className="text-lg">Type a password</FormLabel>
              <FormControl>
                <PasswordToggleWrapper>
                  <FancyInput
                    label="New password"
                    error={form.formState.errors.password}
                    {...field}
                  />
                </PasswordToggleWrapper>
              </FormControl>
              <FormMessage />
            </FormItem>
          )}
        />
        <FormField
          control={form.control}
          name="confirmPassword"
          render={({ field }) => (
            <FormItem>
              <FormLabel className="text-lg">
                Please type your password again
              </FormLabel>
              <FormControl>
                <FancyInput
                  label="Confirm Password"
                  type="password"
                  error={form.formState.errors.confirmPassword}
                  {...field}
                />
              </FormControl>
              <FormMessage />
            </FormItem>
          )}
        />
        <FormMessage>{form.formState.errors.root?.message}</FormMessage>
        <SubmitButton
          className="w-full"
          isPending={isPending}
          disabled={isPending || form.formState.isSubmitSuccessful}
        >
          Submit
        </SubmitButton>
      </form>
    </Form>
  );
};
