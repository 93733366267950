import { useFetchCandidate } from "src/redux/api/hooks/useFetchCandidate";

export const WelcomeText = () => {
  const { candidate } = useFetchCandidate();
  const firstName = candidate?.candidateName?.split(" ")[0];

  return (
    <h1 className="mb-5 text-2xl font-medium text-black">
      Welcome
      {firstName && `, ${firstName}`}
    </h1>
  );
};
