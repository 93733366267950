const donts: string[] = [
  "The wearing of headphones or headsets is not permitted.",
  "Only a primary device (Laptop, Desktop with webcam, Tablet) through which a candidate appears for the Exam will be allowed, and an additional smartphone for scanning and uploading descriptive answers will be allowed.",
  "No one else can be in the room with the candidate.",
  "No talking or gossiping during the Examination.",
  "Breaks are not permitted during the Examination.",
  "Attending phone calls during the Examination is not allowed.",
  "Any complaint from candidates or anybody during or after the examination about the process of examination, invigilator, Exam results, NHWM, or its supportive organization will not be entertained or allowed.",
  "Digital Background wallpaper will not be allowed in the video streaming during the examination.",
  "Candidates are not allowed to stop their Camera or Video during the whole examination duration.",
  "Violation of any of the above and below rules and terms & conditions of the Examination or the Application or the company as well as NHWM, the candidates' candidature will be cancelled automatically along with disciplinary action without prior notice",
  "Blurred background in Video Streaming is not allowed",
  "Candidates must be in Decent Dress-code in the exam, no Homely Dress allowed",
];

const dos: string[] = [
  "It is the candidate's duty to ensure a fast and stable internet connection for the purpose of the examination.",
  "A live photo must be uploaded before the Exam starts.",
  "A Few blank white papers and a Blue Ball-pen (other colored pens are not allowed) are required to complete the descriptive answers. Pencils and erasers are needed. Candidates can color a scientific diagram/figure/picture with appropriate colored pencils",
  "You must verify your identity using a photo ID with your Name, Photo, and Signature on the same side.",
  "The candidate’s attention must remain focused on the Examination. A candidate can not see to-and-fro or left & right during the Examination.",
  "You have to show your room if the invigilator asks during the Examination. A candidate's complete name (according to the document) should be reflected in the video streaming screen during the Examination.",
  "The camera quality of the secondary device should be good so that the scan and upload quality are well-visible.",
];

const NB: string[] = [
  "NHWM and its supportive organization are not responsible if you are unable to attend the exam due to network issues or technical glitches in your device.",
  "Management of NHWM or its supportive organization is not responsible if any candidate drops out due to poor internet connection. Re-appearing is not allowed under any circumstances.",
  "If appearing from an Internet Cafe, be sure that no disturbance at all should be in the cafe.",
  "If any candidate is found guilty of the rules of the Examination or found violating the Terms and Conditions of the Examination or organization, they will be terminated from the Exam, and no further attempt will be allowed. This rule is applicable even after completion or successful submission of the answer script of the Examination.",
  "If a candidate is found guilty in any one exam, they will be terminated for all Exams or Results they have already appeared in or are yet to appear in.",
];

const Terms = () => {
  return (
    <div>
      <div className="flex flex-col items-start space-y-3">
        <div className="rounded-md border border-gray-100 p-3 shadow-md">
          <b>Don’t</b>
          <div className="my-2 h-1 w-full bg-gray-100" />
          <ol>
            {donts.map((dont, index) => (
              <li key={index}>
                <b>{index + 1}. </b>
                {`${dont}`}
              </li>
            ))}
          </ol>
        </div>
        <div className="rounded-md border border-gray-100 p-3 shadow-md">
          <b>Do</b>
          <div className="my-2 h-1 w-full bg-gray-100" />
          <ol>
            {dos.map((dont, index) => (
              <li key={index}>
                <b>{index + 1}. </b>
                {`${dont}`}
              </li>
            ))}
          </ol>
        </div>
        <div className="rounded-md border border-gray-100 p-3 shadow-md">
          <b>N.B.</b>
          <div className="my-2 h-1 w-full bg-gray-100" />
          <ol>
            {NB.map((dont, index) => (
              <li key={index}>
                <b>{index + 1}. </b>
                {`${dont}`}
              </li>
            ))}
          </ol>
        </div>
      </div>
    </div>
  );
};

export default Terms;
