export const NotFound = () => {
  return (
    <div className="flex h-[100dvh] w-full flex-col items-center justify-center gap-6 px-4 text-center">
      <h1 className="text-6xl font-bold tracking-tighter sm:text-8xl">404</h1>
      <p className="max-w-md text-gray-500 dark:text-gray-400">
        Oops, it looks like the page you're looking for doesn't exist.
      </p>
      <a
        href="/sign-in"
        className="inline-flex h-10 items-center justify-center rounded-md bg-gray-900 px-6 text-sm font-medium text-gray-50 shadow transition-colors hover:bg-gray-900/90 focus-visible:outline-none focus-visible:ring-1 focus-visible:ring-gray-950 disabled:pointer-events-none disabled:opacity-50 dark:bg-gray-50 dark:text-gray-900 dark:hover:bg-gray-50/90 dark:focus-visible:ring-gray-300"
      >
        Go to Signin
      </a>
    </div>
  );
};
