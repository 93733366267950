export const POSTS = ["RWO", "AWO", "ITWO", "HRWO", "DWO"] as const;
export type Post = (typeof POSTS)[number];

export type Candidate = {
  id: string;
  candidateName: string;
  dateOfBirth: string;
  stateOfBirth: string;
  baseDistrict: string;
  fatherName: string;
  motherName: string;
  gender: string;
  passportSizedRecentPhotograph: string;
  address: string;
  whatsAppNo: string;
  adharNumber: string;
  profilePicture: string;
  signature: string;
};

export interface ScheduledExam {
  id: number;
  rollNo: string;
  candidateId: number;
  applicationId: number;
  admitCardNumber: string;
  postName: string;
  status: string;
  slot: {
    id: number;
    date: string;
    catagory: string;
    startTime: string;
    endtime: string;
    max_capacity: number;
    slotName: string;
    state: string;
  };
}
export interface AdmitCardDetail {
  id: number;
  rollNo: string;
  candidateId: number;
  applicationId: number;
  admitCardNumber: string;
  postName: string;
  status: string;
  candidateName: string;
  signature: string;
  profilePicture: string;
  slot: {
    id: number;
    date: string;
    catagory: string;
    startTime: string;
    endtime: string;
    max_capacity: number;
    slotName: string;
    state: string;
  };
}

export type AppliedJob = {
  submissionId: number;
  name: string;
  dateOfApplication: string;
  emailAddress: string;
  baseDistrictOrState: string;
  postApplied: Post;
  speciality: string;
  preferredOption: string;
};

export interface Slot {
  id: number;
  date: string;
  catagory: string;
  startTime: string;
  endtime: string;
  maxCapacity: number;
  slotName: string;
  state: string;
}

export interface FetchedEbook {
  hasBought: boolean;
  job: string;
}

export interface Question {
  id: number;
  candidateId: number;
  examinationId: string;
  postApplied: string;
  admitCardNumber: string;
  startTime: string;
  finishTime: string | null;
  dateofexam: string;
  examAppearingPhoto: string | null;
  questionNumber: number;
  answer: string | null;
  answerUrl: string | null;
  question: {
    question_id: number;
    question: string;
    option1?: string;
    option2?: string;
    option3?: string;
    option4?: string;
    catagory: string;
    type: "SHORT" | "OPTIONAL" | "DESC";
    marks: number;
    preparedby: string;
    expectedAns?: string;
    createdAt: string;
  };
}
