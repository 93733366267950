export const searchItems = [
  {
    value: "question-1",
    trigger: "What is NHWM?",
    content:
      'NHWM stands for National Health and Wellness Mission, a programme collaborated by Optim Health and Wellness (OPC) Private Limited. NHWM is a platform dedicated to educating people for proper nutrition and wellness, facilitating with proper Wellness Health Check-Up Programmes. Our programme is empower individuals with knowledge about healthy eating habits and regular health screenings, fostering overall well-being with Wellness Counselling. Our mission is - "Helping and educating 10 million people by 31st Dec\' 2030 across India about how to prevent or reverse lifestyle diseases"',
  },
  {
    value: "question-4",
    trigger: "What are the purposes of NHWM?",
    content:
      "Providing accessible, affordable, and quality healthcare services to all citizens, especially those in rural and remote areas. Strengthening healthcare infrastructure and human resources for health at the primary, secondary, and tertiary levels. Educating individuals about proper nutrition: NHWM aims to provide comprehensive and accessible education on the principles of proper nutrition, including balanced diets, portion control, and the importance of essential nutrients.",
  },
  {
    value: "question-2",
    trigger: "How can I reschedule my exam?",
    content:
      "To reschedule your exam, log in to your account and navigate to the exam schedule section. Select the exam you wish to reschedule and choose a new date and time from the available options. Please note that rescheduling dates may not be available soon. If rescheduling options are available, you can proceed to book a new date.",
  },
  {
    value: "question-3",
    trigger:
      "What should I do if I encounter technical issues during the exam?",
    content:
      "If you encounter any technical issues during the exam, such as problems with the exam platform or internet connectivity issues, please reach out to our technical support team immediately. They will assist you in resolving the issue as quickly as possible.",
  },
  {
    value: "question-5",
    trigger: "How can I access my exam results?",
    content:
      "We will send the exam result to you via email once it has been declared.",
  },
  {
    value: "question-6",
    trigger: "What should I do if I forget my login credentials?",
    content:
      'If you forget your login credentials, you can use the "Forgot Password" option on the login page to reset your password. Alternatively, you can contact our customer support team for assistance in recovering your account.',
  },
  {
    value: "question-7",
    trigger: "How can I attend the exam?",
    content:
      "Start by logging in to your account on the exam portal using your username and password. After logging, you'll typically have the option to schedule your exam. Choose a date and time that works for you from the available options. Keep in mind any deadlines or time constraints associated with the exam.",
  },
  {
    value: "question-8",
    trigger: "Is there a time limit for completing the exam?",
    content:
      "Yes, each exam has a specific time limit for completion, which varies depending on the exam.",
  },
  {
    value: "question-9",
    trigger: "Will I receive a certificate upon passing the exam?",
    content: "No.",
  },
  {
    value: "question-10",
    trigger: "Can I request a score report or transcript for my records?",
    content: "No.",
  },
  {
    value: "question-11",
    trigger: "Is there a helpline or live chat available for immediate help?",
    content: "Our helpline no is 03617968083",
  },
];

export type AccordionItem = (typeof searchItems)[number];
