import { env } from "src/lib/env";
import { axiosAuth } from "./auth";

export const getAdmitCard = async (submissionId: number) => {
  try {
    const api = axiosAuth();
    const response = await api.post(`${env.REACT_APP_API_URL}/getadmitcard`, {
      submissionId: submissionId,
    });
    if (response.data.message.toLowerCase() === "success") {
      return response.data.data.scheduledExam;
    } else {
      console.log("admit card is not available.");
    }
  } catch (error) {
    console.error(error, "somthing went wrong.");
  }
};

export const hasbookedExamSlot = async (slotId: number) => {
  const response = await axiosAuth().post(
    `${env.REACT_APP_API_URL}/hasbookedslot`,
    {
      slotId: slotId,
    },
  );
  return response.data.data;
};

export const createAdmitCard = async (slotId: number, submissionId: number) => {
  const api = axiosAuth();
  const response = await api.post(`${env.REACT_APP_API_URL}/createadmitcard`, {
    rollNo: `OH-${submissionId}`,
    applicationId: submissionId,
    slotId: slotId,
  });
  return response.data;
};
