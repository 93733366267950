import { create } from "zustand";
import { createJSONStorage, persist } from "zustand/middleware";

interface TimerStore {
  timer: number;
  timerInterval?: NodeJS.Timeout;
  startTimer: () => void;
  stopTimer: () => void;
  onTimerEnd: () => void;
  clearStore: () => void;
}

export const useMcqTimerStore = create(
  persist<TimerStore>(
    (set, get, api) => ({
      timer: 1800, // time in seconds
      onTimerEnd: () => {}, // Default empty function for the callback
      startTimer: () => {
        const timerInterval = setInterval(() => {
          set((state) => {
            if (state.timer === 0) {
              state.onTimerEnd(); // Invoke the callback when the timer ends
            }

            return {
              timer: state.timer === 0 ? 0 : state.timer - 1,
            };
          });
        }, 1000);
        set({ timerInterval });
      },
      stopTimer: () => {
        const state = get();
        clearInterval(state.timerInterval);
      },
      clearStore: () => {
        api.persist.clearStorage();
      },
    }),
    {
      name: "mcqtimer",
      storage: createJSONStorage(() => sessionStorage),
    },
  ),
);

export const useDescTimerStore = create(
  persist<TimerStore>(
    (set, get, api) => ({
      timer: 3600, // time in seconds
      onTimerEnd: () => {}, // Default empty function for the callback
      startTimer: () => {
        const timerInterval = setInterval(() => {
          set((state) => {
            if (state.timer === 0) {
              state.onTimerEnd(); // Invoke the callback when the timer ends
            }

            return {
              timer: state.timer === 0 ? 0 : state.timer - 1,
            };
          });
        }, 1000);
        set({ timerInterval });
      },
      stopTimer: () => {
        const state = get();
        clearInterval(state.timerInterval);
      },
      clearStore: () => {
        api.persist.clearStorage();
      },
    }),
    {
      name: "desctimer",
      storage: createJSONStorage(() => sessionStorage),
    },
  ),
);
