import { DialogClose } from "@radix-ui/react-dialog";
import { ReactNode } from "react";
import {
  Dialog,
  DialogContent,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from "./dialog";

interface NextSectionDialogProps {
  children: ReactNode;
  onClick: () => void;
}

const NextSectionDialog = ({ children, onClick }: NextSectionDialogProps) => {
  return (
    <Dialog>
      <DialogTrigger>{children}</DialogTrigger>
      <DialogContent className="bg-white">
        <DialogHeader>
          <DialogTitle className="text-center">
            Are you sure want to Go to the next section of the Exam? You will
            not be able to come back to this section again.
          </DialogTitle>
        </DialogHeader>
        <div className="flex justify-between space-y-1">
          <DialogClose>
            <button className="inline-flex h-9 items-center justify-center whitespace-nowrap rounded-md border border-gray-600 bg-gray-300/80 px-4 py-2 text-sm font-medium transition-colors hover:bg-gray-300/50 focus-visible:outline-none focus-visible:ring-1 focus-visible:ring-ring disabled:pointer-events-none disabled:opacity-50">
              Review
            </button>
          </DialogClose>
          <DialogClose>
            <button
              className="inline-flex h-9 items-center justify-center whitespace-nowrap rounded-md bg-primary px-4 py-2 text-sm font-medium text-white transition-colors hover:border hover:border-primary hover:bg-[#8B70D7]/60 focus-visible:outline-none focus-visible:ring-1 focus-visible:ring-ring disabled:pointer-events-none disabled:opacity-50"
              onClick={onClick}
            >
              Next Section
            </button>
          </DialogClose>
        </div>
      </DialogContent>
    </Dialog>
  );
};

export default NextSectionDialog;
