import { NavigationCards } from "./navigation-cards";
import { DashboardLayout } from "./DashboardLayout";

import { Exams } from "src/components/ui/exams";
import { DashboardHeader } from "./dashboard-header";
import { Notifications } from "./notifications";
import { FAQCard } from "src/components/ui/faq-card";
import GuidelineBubble from "src/components/ui/guideline-bubble";

export const DashboardView = () => {
  return (
    <DashboardLayout>
      <main className="flex flex-grow flex-col gap-10 px-2 py-3">
        <DashboardHeader />
        <section className="flex h-full w-full flex-col items-center justify-center">
          <div className="flex flex-col gap-20 justify-self-center md:flex-row">
            <NavigationCards />
          </div>
        </section>
        <Notifications />
        <div className="mx-auto overflow-auto rounded-lg border p-3 shadow-lg lg:min-h-[250px] lg:w-[600px] xl:w-[800px]">
          <Exams />
        </div>
        <FAQCard className="pt-16 lg:hidden" />
      </main>
      <FAQCard className="absolute bottom-3 left-10 hidden w-[330px] lg:block" />
      <GuidelineBubble />
    </DashboardLayout>
  );
};
