import { useFetchAllSlotDates } from "src/redux/api/hooks/useFetchAllSlotDates";
import { Calendar } from "./ui/calendar";

interface DesktopExamCalendarProps {
  onSelect: (day: any) => void;
  selectedDay: Date | undefined;
}

export const DesktopExamCalendar = ({
  onSelect,
  selectedDay,
}: DesktopExamCalendarProps) => {
  const { dates } = useFetchAllSlotDates();

  return (
    <>
      <Calendar
        fromDate={new Date(new Date().setDate(new Date().getDate() + 1))}
        toYear={new Date().getFullYear() + 1}
        className="rounded-md text-xl font-light text-zinc-700"
        mode="single"
        styles={{
          caption_label: {
            fontWeight: 500,
          },
          cell: {
            paddingLeft: "0.45rem",
            paddingRight: "0.45rem",
          },
        }}
        modifiers={{
          examDate: dates,
        }}
        modifiersClassNames={{
          examDate: "!bg-primary !text-black !font-semibold",
          today: "!bg-[#ACE1AF] !text-black",
        }}
        selected={selectedDay}
        onSelect={onSelect}
      />
    </>
  );
};

export const MobileExamCalendar = () => {
  const { dates } = useFetchAllSlotDates();

  return (
    <Calendar
      fromDate={new Date(new Date().setDate(new Date().getDate() + 1))}
      toYear={new Date().getFullYear() + 1}
      className="rounded-md text-xl font-light text-zinc-700"
      mode="single"
      styles={{
        caption_label: {
          fontWeight: 500,
        },
      }}
      modifiers={{
        examDate: dates,
      }}
      modifiersClassNames={{
        examDate: "!bg-primary !text-black !font-semibold",
      }}
    />
  );
};
