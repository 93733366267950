import { SearchIcon } from "lucide-react";
import { useState } from "react";
import { Input } from "src/components/ui/input";
import { type AccordionItem } from "./results";

export const SearchBar = ({
  accordionItems,
}: {
  accordionItems: AccordionItem[];
}) => {
  const [searchResults, setSearchResults] = useState<AccordionItem[]>([]);

  const handleQueryChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const query = event.target.value.toLowerCase().trim(); // Improved: Trim leading/trailing whitespace

    if (!query) {
      setSearchResults([]); // Clear results if query is empty
      return;
    }

    const results = accordionItems.filter((item) =>
      item.trigger.toLowerCase().includes(query.toLowerCase()),
    );

    setSearchResults(results.slice(0, 4)); // Set the state to the top 5 results
  };

  return (
    <div className="relative">
      <div className="flex w-full items-center gap-2 border-2 border-red-300 px-3 py-2 shadow-xl focus-within:border-red-500">
        <Input
          className="flex-1 border-0 shadow-none outline-none ring-0 focus-visible:ring-0"
          placeholder="Search"
          type="text"
          onChange={handleQueryChange}
        />
        <SearchIcon className="size-4 text-red-500" />
      </div>
      {searchResults.length > 0 && (
        <div className="absolute top-full z-10 max-h-96 w-full overflow-y-auto rounded-lg rounded-t-none border-2 border-t-0 border-red-500 bg-white p-3 px-10 text-black shadow-xl">
          {searchResults.map((result, index) => (
            <div key={index} className="mt-2">
              <ul className="border-b py-1">
                <li className="w-full cursor-pointer rounded-sm px-1 py-4 hover:bg-gray-100">
                  <a href={`#${result.value}`} className="">
                    {result.trigger}
                  </a>
                </li>
              </ul>
            </div>
          ))}
        </div>
      )}
    </div>
  );
};
