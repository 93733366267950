import { Checkbox } from "src/components/ui/checkbox";

export const CheckSpam = () => {
  return (
    <div className="flex justify-center items-center gap-2">
      <Checkbox checked aria-readonly className="size-6" />
      <span className="text-sm">Make sure to check your spam folder</span>
    </div>
  );
};
