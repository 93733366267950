import { useFetchExams } from "src/redux/api/hooks/useFetchExams";
import {
  Table,
  TableHeader,
  TableRow,
  TableHead,
  TableBody,
  TableCell,
} from "./table";
import { convertDateToIST, convertTimeToIST } from "src/lib/utils";
import { useNavigate } from "react-router-dom";
import { Button } from "./button";

const LoadingSkeleton = () => (
  <Table>
    <TableHeader>
      <TableRow>
        <TableHead className="w-64 md:text-xl">Post</TableHead>
        <TableHead className="w-64 text-end md:text-xl">Date & Time</TableHead>
      </TableRow>
    </TableHeader>
    <TableBody>
      {[...Array(5)].map((_, index) => (
        <TableRow key={index}>
          <TableCell className="h-6 w-64 animate-pulse bg-gray-200 md:text-xl"></TableCell>
          <TableCell className="h-6 w-64 animate-pulse bg-gray-200 text-end md:text-xl"></TableCell>
        </TableRow>
      ))}
    </TableBody>
  </Table>
);

export const Exams = () => {
  const { scheduledExams, isPending } = useFetchExams();
  const navigate = useNavigate();

  if (isPending) {
    return <LoadingSkeleton />;
  }

  return (
    <div>
      <h1 className="px-2 py-4 text-xl font-bold">Coming Exams</h1>
      <Table>
        <TableHeader>
          <TableRow>
            <TableHead className="w-64 md:text-xl">Post</TableHead>
            <TableHead className="w-64 text-center md:text-xl">
              Date & Time
            </TableHead>
          </TableRow>
        </TableHeader>
        <TableBody>
          {scheduledExams.length === 0 && (
            <TableCell
              colSpan={2}
              className="col-span-2 w-full py-3 text-center font-semibold md:text-xl"
            >
              No exams available
            </TableCell>
          )}
          {scheduledExams.map(({ exam, isExamCloseForPost, isMissed }) => (
            <TableRow key={exam.id} className="mt-2">
              <TableCell className="md:text-xl">{exam.postName}</TableCell>
              <TableCell className="text-center md:text-xl">
                {convertDateToIST(new Date(exam.slot.date).toUTCString())} -{" "}
                {convertTimeToIST(exam.slot.startTime)}
              </TableCell>
              <TableCell className="text-end">
                {exam.status.toLowerCase() === "appeared" ? (
                  <span className="text-lg font-medium uppercase text-green-500">
                    Appeared
                  </span>
                ) : isMissed ? (
                  <span className="text-lg font-medium uppercase text-red-500">
                    Exam Missed
                  </span>
                ) : (
                  <Button
                    onClick={() => {
                      isExamCloseForPost &&
                        navigate(`/start?admit=${exam.admitCardNumber}`);
                    }}
                    variant={isExamCloseForPost ? "default" : "secondary"}
                    disabled={!isExamCloseForPost}
                  >
                    Start Exam
                  </Button>
                )}
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
      <div></div>
    </div>
  );
};
