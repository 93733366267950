import { useEffect, useRef, useState } from "react";
import { useForm } from "react-hook-form";
import { Button } from "src/components/ui/button";
import Terms from "./Terms";
import {
  startExam,
  uploadExamPicture,
  validateAdmit,
} from "src/redux/api/exam";
import { ValidationSchema } from "src/schemas/appear-exam";
import { zodResolver } from "@hookform/resolvers/zod";
import { z } from "zod";
import Webcam from "react-webcam";
import { generateUUID, getSubmissionId } from "src/lib/utils";
import useQueryParams from "src/redux/api/hooks/useQueryParams";
import { Link } from "react-router-dom";
import { Modal } from "react-overlays";
import Backdrop from "src/components/ui/Backdrop";
import { useMcqAnswerStore } from "src/components/zustand/AnswerStore";
import ZoomLinkDialog from "src/components/ui/zoom-link-dialog";
import { getAdmitCard } from "src/redux/api/admit-card";

type View = "guideline" | "start";

const ExamPageView = () => {
  const [isCameraOpen, setIsCameraOpen] = useState(false);
  const [livePictureURL, setLivePictureURL] = useState<string | null>(null);
  const [livePictureBlob, setLivePictureBlob] = useState<Blob | null>(null);
  const [isModalOpen, setIsModalOpen] = useState(true);
  const [acceptedTerms, setAcceptedTerms] = useState(false);
  const [apiError, setApiError] = useState("");

  const [view, setView] = useState<View>("guideline");

  const uuid = useMcqAnswerStore();
  const { admit } = useQueryParams(["admit"]);

  const {
    register,
    handleSubmit,
    setError,
    formState: { errors, isSubmitting },
  } = useForm<z.infer<typeof ValidationSchema>>({
    resolver: zodResolver(ValidationSchema),
  });

  const webcamRef = useRef<Webcam>(null);

  const startCamera = async () => {
    try {
      const stream = await navigator.mediaDevices.getUserMedia({
        video: {
          facingMode: "user",
          width: { ideal: 1280 },
          height: { ideal: 720 },
        },
      });

      if (webcamRef.current?.video) {
        webcamRef.current.video.srcObject = stream;
      }

      setIsCameraOpen(true);
    } catch (error) {
      console.error("Error accessing camera:", error);
    }
  };

  const capturePicture = () => {
    if (webcamRef.current) {
      const imageSrc = webcamRef.current.getScreenshot();

      if (imageSrc) {
        const blob = dataURItoBlob(imageSrc);

        setLivePictureURL(imageSrc);
        setLivePictureBlob(blob);

        // Remove the webcam feed
        const videoElement = document.querySelector("video");
        if (videoElement) {
          videoElement.style.display = "none";
        }
      }
    }
  };

  const recapturePicture = () => {
    const videoElement = document.querySelector("video");
    if (videoElement) {
      videoElement.style.display = "block";
    }

    setLivePictureURL(null);
  };

  const onSubmit = async (e: z.infer<typeof ValidationSchema>) => {
    try {
      await validateAdmit(e.admitcardnumber);
    } catch (error) {
      setError("admitcardnumber", {
        message: `Are you sure you have entered the correct admit card number?`,
      });
      return;
    }

    try {
      // TODO: Add these lines back
      if (!livePictureBlob) {
        setApiError("Please capture your picture.");
        return;
      }
      const file = new File([livePictureBlob], "livePicture.jpg", {
        type: "image/jpeg",
      });

      const randomUUID = generateUUID();

      uuid.setUuid(randomUUID);

      const response = await startExam(e.admitcardnumber, randomUUID);
      const examinationId = response?.data.examinationId;

      await uploadExamPicture(
        file,
        "livePicture.jpg",
        e.admitcardnumber,
        examinationId,
      );

      window.location.replace(
        `start/exam?admit=${response?.data.admitCardNumber}&exam=${response?.data.examinationId}`,
      );
    } catch (error) {
      console.log(error);
      setApiError("You cannot start the exam.");
    }
  };

  // Utility function to convert data URI to Blobz
  const dataURItoBlob = (dataURI: string) => {
    const byteString = atob(dataURI.split(",")[1]);
    const mimeString = dataURI.split(",")[0].split(":")[1].split(";")[0];
    const ab = new ArrayBuffer(byteString.length);
    const ia = new Uint8Array(ab);

    for (let i = 0; i < byteString.length; i++) {
      ia[i] = byteString.charCodeAt(i);
    }

    return new Blob([ab], { type: mimeString });
  };

  // countdown timer

  const submissionId = getSubmissionId(admit!);
  const [targetTimeString, setTargetTimeString] = useState<string>("");
  const [zoomLink, setZoomLink] = useState("");

  const getExamDetails = async (submissionId: string) => {
    try {
      if (Number(submissionId)) {
        const response = await getAdmitCard(Number(submissionId));
        if (response) {
          setTargetTimeString(response.slot.startTime);
          setZoomLink(response.slot.zoom_link);
        }
      }
    } catch (error) {
      console.error(error);
    }
  };
  useEffect(() => {
    getExamDetails(submissionId);
  }, [submissionId]);

  const handleZoomLink = () => {
    window.open(zoomLink, "_blank", "noopener,noreferrer");
  };

  const [activeBtn, setActiveBtn] = useState(true);

  const handleExamStartTimerEnd = () => {
    setActiveBtn(false);
  };

  const renderView = () => {
    switch (view) {
      case "guideline":
        return (
          <>
            <h1 className="mb-4 rounded-md bg-gray-100 p-2 text-2xl font-semibold">
              Exam Guidelines
            </h1>
            <div className="max-h-max overflow-y-auto text-start text-sm text-gray-600 md:text-base">
              <Terms />
              <div className="mt-4 flex justify-center">
                <div>
                  <input
                    type="checkbox"
                    id="terms"
                    checked={acceptedTerms}
                    onChange={() => setAcceptedTerms(!acceptedTerms)}
                  />
                  <label
                    className="ml-2 text-sm font-medium leading-none"
                    htmlFor="terms"
                  >
                    Accept terms and conditions of the exam rules
                  </label>
                </div>
              </div>
            </div>

            <button
              className={`mt-4 w-full rounded bg-blue-500 px-4 py-2 font-bold text-white hover:bg-blue-700 ${
                !acceptedTerms && "btn-disabled opacity-50"
              }`}
              onClick={() => {
                setView("start");
              }}
              disabled={!acceptedTerms}
            >
              Next
            </button>
          </>
        );
      case "start":
        return (
          <div className="flex flex-col items-center justify-center gap-5 rounded bg-slate-100 p-4 shadow-md">
            <div className="mb-4 text-center text-2xl font-bold text-red-600">
              Please make sure before starting the Exam
            </div>
            <div className="mb-4 text-center text-xl">
              <p>Do not Refresh or Change tabs during your Exam.</p>
              <p className="my-2 text-xl text-red-500">
                Join the zoom Link before starting the Exam
              </p>
              <p className="my-2 text-xl">
                Follow the Proctor's instruction, don't be in hurry.
              </p>
              <ZoomLinkDialog onClick={handleZoomLink}>
                <Button>Join Zoom</Button>
              </ZoomLinkDialog>
            </div>
            <div>
              <p className="text-center font-mono text-lg">
                You can Start the Exam in{" "}
                <CountdownTimer
                  targetTime={targetTimeString}
                  onTimerEnd={handleExamStartTimerEnd}
                />
              </p>
            </div>
            <Button onClick={() => setIsModalOpen(false)} disabled={activeBtn}>
              Start the exam
            </Button>
          </div>
        );
      default:
        break;
    }
  };

  return (
    <>
      <section className="mx-auto h-screen max-w-screen-2xl place-items-center">
        <div>
          <Link to="/">
            <h1 className="text-2xl font-extrabold">NHWM</h1>
          </Link>
        </div>
        <div className="flex h-[80vh] items-center">
          <div className="mx-auto flex h-[50vh] w-1/2 basis-1/2 flex-col items-center justify-center gap-5">
            <span>Click a live picture</span>
            <div
              id="live-picture-container"
              className={`flex flex-col justify-center ${
                !livePictureURL && "h-96 w-96 outline outline-1"
              }`}
            >
              {isCameraOpen && <Webcam ref={webcamRef} />}
            </div>
            {!isCameraOpen ? (
              <Button onClick={startCamera}>Start Camera</Button>
            ) : (
              <>
                {!livePictureURL ? (
                  <Button onClick={capturePicture}>Capture Picture</Button>
                ) : (
                  <>
                    <picture>
                      <img src={livePictureURL} alt="Captured Picture" />
                    </picture>
                    <Button onClick={recapturePicture}>Recapture?</Button>
                  </>
                )}
              </>
            )}
          </div>
          <div className="mx-auto flex h-[50vh] min-h-max w-2/5 items-center">
            <form
              className="flex w-full flex-col items-center gap-3"
              onSubmit={handleSubmit(onSubmit)}
            >
              <input
                {...register("admitcardnumber")}
                className="h-8 w-[70%] rounded-md border border-secondary bg-slate-50 p-2 focus:border-primary focus:outline-none focus:ring-1 focus:ring-primary"
                type="text"
                defaultValue={admit || ""}
                alt="admit card number"
                placeholder="Admit Card Number"
              />
              <span className="text-sm text-red-500">
                {errors.admitcardnumber?.message}
              </span>
              <span className="text-center text-sm text-red-500">
                {apiError}
              </span>
              {/* TODO add live picture condition */}
              {
                <Button
                  type="submit"
                  className="mx-auto w-3/4"
                  disabled={isSubmitting}
                >
                  Submit and Start the Exam
                </Button>
              }
              <h1 className="text-lg font-semibold text-red-500">
                Please turn on the Zoom Camera before starting the Exam.
              </h1>
            </form>
          </div>
        </div>
      </section>
      <Modal
        show={isModalOpen}
        onHide={() => setIsModalOpen(false)}
        onEscapeKeyDown={(e: KeyboardEvent) => e.preventDefault()}
        aria-labelledby="exam rules!"
        backdrop={true}
        renderBackdrop={(props) => <Backdrop {...props} />}
        style={{
          boxShadow: "0 5px 15px rgba(0, 0, 0, 0.9)",
          padding: "10px",
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          width: "60%",
          background: "white",
          zIndex: 1040,
        }}
      >
        <div className="flex h-[90vh] w-full flex-col justify-center pb-8 text-center">
          {renderView()}
        </div>
      </Modal>
    </>
  );
};

export default ExamPageView;

interface CountdownTimerProps {
  targetTime: string;
  onTimerEnd: () => void;
}

const CountdownTimer: React.FC<CountdownTimerProps> = ({
  targetTime,
  onTimerEnd,
}) => {
  const [remainingTime, setRemainingTime] = useState<string>("00:00");

  const serverTimeString = new Date().toUTCString();
  const serverTime = new Date(serverTimeString).getTime();
  const localTime = Date.now();
  const offset = serverTime - localTime;
  const targetTimemili = new Date(targetTime).getTime();

  useEffect(() => {
    const intervalId = setInterval(() => {
      const now = Date.now();
      const adjustedTime = now + offset;

      const timeRemaining = Math.max(0, targetTimemili - adjustedTime);

      if (timeRemaining <= 0) {
        clearInterval(intervalId);
        onTimerEnd();
      } else {
        const minutes = Math.floor(timeRemaining / (1000 * 60));
        const seconds = Math.floor((timeRemaining % (1000 * 60)) / 1000);
        setRemainingTime(
          `${String(minutes).padStart(2, "0")}:${String(seconds).padStart(2, "0")}`,
        );
      }
    }, 1000);

    return () => {
      clearInterval(intervalId); // Clean up the interval when the component unmounts
    };
  }, [targetTime]);

  return (
    <>
      <span className="text-xl">{remainingTime}</span>
    </>
  );
};
