import { z } from "zod";

const MB_BYTES = 1000000;

const ACCEPTED_MIME_TYPES = [
  "image/jpeg",
  "image/jpg",
  "image/png",
  "image/webp",
];

export const UploadAdmitPicSchema = z.object({
  admitPicture: z
    .instanceof(File)
    .superRefine((f, ctx) => {
      if (!ACCEPTED_MIME_TYPES.includes(f.type)) {
        ctx.addIssue({
          code: z.ZodIssueCode.custom,
          message: `File must be one of ${ACCEPTED_MIME_TYPES.join(
            ", ",
          )} but was ${f.type}`,
        });
      }
      if (f.size > 2 * MB_BYTES) {
        ctx.addIssue({
          code: z.ZodIssueCode.too_big,
          type: "array",
          message: `The file must not be larger than ${2 * MB_BYTES} bytes: ${
            f.size
          }`,
          maximum: 2 * MB_BYTES,
          inclusive: true,
        });
      }
    })
    .optional(),
});

export const UploadSignatureSchema = z.object({
  signature: z
    .instanceof(File)
    .superRefine((f, ctx) => {
      if (!ACCEPTED_MIME_TYPES.includes(f.type)) {
        ctx.addIssue({
          code: z.ZodIssueCode.custom,
          message: `File must be one of ${ACCEPTED_MIME_TYPES.join(
            ", ",
          )} but was ${f.type}`,
        });
      }
      if (f.size > 2 * MB_BYTES) {
        ctx.addIssue({
          code: z.ZodIssueCode.too_big,
          type: "array",
          message: `The file must not be larger than ${2 * MB_BYTES} bytes: ${
            f.size
          }`,
          maximum: 2 * MB_BYTES,
          inclusive: true,
        });
      }
    })
    .optional(),
});
