import { useEffect, useState } from "react";
import { FetchedEbook, Post } from "src/lib/entities";
import { useFetchAppliedJobs } from "./useFetchAppliedJobs";
import { getBooksOrdered } from "../e-book";

export const useFetchEbooks = () => {
  const [ebooks, setEbooks] = useState<FetchedEbook[]>([]);
  const [isPending, setIsPending] = useState(true);

  const { appliedJobs } = useFetchAppliedJobs();

  useEffect(() => {
    getBooksOrdered()
      .then(({ status, eBooks }) => {
        const updatedEbooks = appliedJobs.map((job) => {
          const hasBought = !!eBooks.find(
            (book: Post) => book === job.postApplied,
          );
          return {
            hasBought,
            job: job.postApplied,
          };
        });
        setEbooks(updatedEbooks);
      })
      .finally(() => {
        setIsPending(false);
      });
  }, [appliedJobs]);

  return { ebooks, isPending };
};
