import { Button } from "src/components/ui/button";
import { Card, CardContent } from "src/components/ui/card";
import { Skeleton } from "src/components/ui/skeleton";
import { EbookDialog } from "./e-book-dialog";

export interface EbookProps {
  hasBought: boolean;
  job: string;
}

export const Ebook = ({ hasBought, job }: EbookProps) => {
  const getImageSrc = (jobRole: string) => {
    return `/${jobRole}.jpg`;
  };

  return (
    <Card className="m-2 w-60 border-0 shadow-none">
      <CardContent className="h-[330px] space-y-2 rounded-lg !bg-[#FCF1E3] bg-inherit p-2">
        <img
          src={getImageSrc(job.toUpperCase())}
          alt={job}
          className="mx-auto h-[230px] rounded-lg object-cover"
        />
        <div className="px-5 text-xl font-medium">{job.toUpperCase()}</div>
        <div className="flex items-center justify-end gap-2">
          <span className="font-semibold">
            {hasBought ? "Read Now" : "Order Now"}
          </span>
          <EbookDialog hasBought={hasBought} job={job} />
        </div>
      </CardContent>
    </Card>
  );
};

export const EbookSkeleton = () => {
  return (
    <Card className={`m-2 h-80 w-60 border-0 shadow-none`}>
      <Skeleton className="space-y-2 p-2">
        <Skeleton className="h-[230px] w-[220px] rounded-lg" />
        <Skeleton className="h-[10px] w-[50px]" />
        <Skeleton className="h-[10px] w-[40px]" />
        <div className="flex items-center justify-end gap-2">
          <Skeleton className="h-[20px] w-[130px]" />
          <Button className={`bg-gray-600/10 text-xs`} disabled>
            {">"}
          </Button>
        </div>
      </Skeleton>
    </Card>
  );
};
