interface BackdropProps extends React.HTMLProps<HTMLDivElement> {
  onClick?: (event: React.MouseEvent<HTMLDivElement>) => void;
}

const Backdrop: React.FC<BackdropProps> = ({ onClick ,...props }) => {
  const backdropStyles: React.CSSProperties = {
    position: 'fixed',
    zIndex: 1040,
    top: 0,
    bottom: 0,
    left: 0,
    right: 0,
    backgroundColor: '#000',
    opacity: 0.9,
  };

  const handleClick = (e:React.MouseEvent<HTMLDivElement>)=>{e.stopPropagation()}

  return <div style={backdropStyles} {...props} onClick={handleClick}/>;
};

export default Backdrop;
