import { Checkbox } from "src/components/ui/checkbox";

interface TermsAgreementProps {
  checked: boolean;
  onCheckedChange: (value: boolean) => void;
}

export const TermsAgreement = ({
  checked,
  onCheckedChange,
}: TermsAgreementProps) => {
  return (
    <div className="flex items-center gap-3">
      <Checkbox checked={checked} onCheckedChange={onCheckedChange} className="size-5" />
      <span className="text-sm md:text-base">
        I agree to{" "}
        <a href="/terms" className="text-primary hover:underline">
          Terms and Conditions
        </a>
      </span>
    </div>
  );
};
