import { Exams } from "src/components/ui/exams";
import { AppliedPosts } from "./applied-posts";
import { DashboardLayout } from "../Dashboard/DashboardLayout";
import { DesktopExamCalendar } from "src/components/exam-calendar";
import { useFetchAllSlotDates } from "src/redux/api/hooks/useFetchAllSlotDates";
import { useEffect, useState } from "react";
import { convertDateToIST } from "src/lib/utils";
import { SelectSlots } from "../SelectSlot/select-slots";
import AdmitCardLayout from "../AdmitCard/AdmitCardLayout";
import { AdmitCardDetail } from "src/lib/entities";
import { getAdmitCard } from "src/redux/api/admit-card";
import { useFetchExams } from "src/redux/api/hooks/useFetchExams";
import { toast } from "sonner";
import { Button } from "src/components/ui/button";

type View = "home" | "select" | "admit";

export const AppliedPostsView = () => {
  const [view, setView] = useState<View>("home");

  const { dates } = useFetchAllSlotDates();
  const [selectedDay, setSelectedDay] = useState<Date>(new Date());
  const { scheduledExams } = useFetchExams();
  const [admitCard, setAdmitCard] = useState<AdmitCardDetail>();

  const slotDatesArray: string[] = [];
  dates.forEach((date) => {
    slotDatesArray.push(convertDateToIST(new Date(date).toUTCString()));
  });

  const handleSelectDay = (day: Date) => {
    if (
      day &&
      slotDatesArray.includes(convertDateToIST(new Date(day).toUTCString()))
    ) {
      setSelectedDay(day);
      setView("select");
    }
  };

  const scheduledExamsIdArray: number[] = [];
  scheduledExams.forEach((exam) => {
    scheduledExamsIdArray.push(exam.exam.applicationId);
  });

  const admitCardDetail = async (id: number) => {
    try {
      const response = await getAdmitCard(id);
      if (response.status.toLowerCase() === "scheduled" || "appeared") {
        setAdmitCard(response);
      }
    } catch (error) {
      console.error(error, "something went wrong.");
    }
  };

  const viewAdmitCard = (id: number) => {
    if (scheduledExamsIdArray.includes(id)) {
      admitCardDetail(id);
      if (admitCard) {
        setView("admit");
      }
    } else {
      toast.error("Exam is not Scheduled.");
    }
  };

  const handleBack = () => {
    setView("home");
    setSelectedDay(new Date());
  };

  const renderView = () => {
    switch (view) {
      case "home":
        return (
          <DashboardLayout>
            <div className="flex flex-grow flex-col justify-around gap-4 overflow-y-auto px-2 py-3">
              <div className="flex flex-col gap-4 xl:flex-row xl:justify-between">
                <div>
                  <h2 className="mb-5 text-xl font-bold text-zinc-700 lg:text-2xl">
                    Applied posts:
                  </h2>
                  <AppliedPosts
                    viewAdmitCard={viewAdmitCard}
                    isScheduled={scheduledExamsIdArray}
                  />
                </div>
                <div>
                  <h2 className="mb-5 text-xl font-bold text-zinc-700 lg:text-2xl">
                    Dates:
                  </h2>
                  <div className="justify-center lg:flex">
                    <DesktopExamCalendar
                      onSelect={handleSelectDay}
                      selectedDay={selectedDay}
                    />
                  </div>
                </div>
              </div>
              <div>
                <div className="mx-auto mt-[5rem] overflow-auto rounded-lg border p-3 shadow-lg lg:max-h-[280px] lg:min-h-[250px] lg:w-[600px] xl:w-[800px]">
                  <Exams />
                </div>
              </div>
            </div>
          </DashboardLayout>
        );
      case "select":
        return (
          <div className="flex min-h-[90dvh] items-center justify-center">
            <SelectSlots selectedDay={selectedDay} handleBack={handleBack} />
          </div>
        );
      case "admit":
        return (
          <div className="m-auto max-w-[1200px]">
            <div className="flex justify-between p-2 print:hidden">
              <p className="text-2xl">Admit Card</p>
              <div className="join gap-3">
                <Button variant="destructive" onClick={() => setView("home")}>
                  Back
                </Button>
              </div>
            </div>
            {admitCard && <AdmitCardLayout schedule={admitCard} />}
          </div>
        );
      default:
        break;
    }
  };

  return <div>{renderView()}</div>;
};
