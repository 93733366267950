import { cn } from "src/lib/utils";
import { DesktopSidebarButtons } from "./desktop-sidebar-buttons";

interface DesktopSidebarProps {
  className?: string;
}
export const DesktopSidebar = ({ className }: DesktopSidebarProps) => {
  return (
    <div
      className={cn(
        "flex-col flex-grow justify-start h-full w-[320px] hidden lg:flex py-3 px-2 border-0 border-r-2",
        className
      )}
    >
      <a className="flex items-cente px-6" href="/">
        <img
          src="https://nhwm-job-portal.s3.ap-south-1.amazonaws.com/app-images/logo.png"
          alt="Logo"
          className="size-8 mr-2"
        />
        <span className="font-bold text-2xl">NHWM</span>
      </a>
      <div className="flex flex-col mt-5 gap-y-3">
        <DesktopSidebarButtons />
      </div>
    </div>
  );
};
