import axios, { AxiosResponse } from "axios";
import { env } from "src/lib/env";

interface UploadResponse {
  message: string;
  data: {
    url: string;
    Key: string;
  };
}

interface SaveAnswerResponse {
  message: string;
  data: {
    id: number;
  };
}

interface AnsUploadConfirmationResponse {
  message: string;
  data: {
    uploaded: boolean;
  };
}

export const uploadAnswerImage = async (
  token: string,
  examId: string,
  questionNumber: number,
  id: number,
  file: File
): Promise<UploadResponse> => {
  const endpoint = `${env.REACT_APP_API_URL}/getuploadanswerurl?file=${file.name}`;

  // const formData = new FormData();
  // formData.append("id", String(id));
  // formData.append("examinationId", examId);
  // formData.append("questionNumber", String(questionNumber));
  // formData.append("file", file);

  const headers = {
    Authorization: `Bearer ${token}`,
  };

  try {
    const response: AxiosResponse<UploadResponse> = await axios.post(
      endpoint,
      {
        id,
        examinationId: examId,
        questionNumber,
      },
      {
        headers,
      }
    );

    await axios.put(response.data.data.url, file);

    return response.data;
  } catch (error) {
    throw new Error(`Error uploading answer image: ${error}`);
  }
};

export const saveAnswerImage = async (
  token: string,
  id: number,
  imageUrl: string,
  uniqueId: string
): Promise<SaveAnswerResponse> => {
  const endpoint = `${env.REACT_APP_API_URL}/saveanswer`;

  const headers = {
    Authorization: `Bearer ${token}`,
  };

  const requestBody = {
    id,
    imageUrl,
    uniqueId,
  };

  try {
    const response: AxiosResponse<SaveAnswerResponse> = await axios.post(
      endpoint,
      requestBody,
      {
        headers,
      }
    );

    return response.data;
  } catch (error) {
    throw new Error(`Error saving answer: ${error}`);
  }
};

export const confirmAnswerImageUpload = async (
  token: string,
  key: string
): Promise<AnsUploadConfirmationResponse> => {
  const endpoint = `${env.REACT_APP_API_URL}/ansuploadconfirmation`;

  const headers = {
    Authorization: `Bearer ${token}`,
  };

  const requestBody = {
    key,
  };

  try {
    const response: AxiosResponse<AnsUploadConfirmationResponse> =
      await axios.post(endpoint, requestBody, {
        headers,
      });

    return response.data;
  } catch (error) {
    throw new Error(`Error confirming answer image upload: ${error}`);
  }
};
