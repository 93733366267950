import { z } from "zod";

export const SignInFormSchema = z.object({
  email: z.string().email(),
  password: z.string(),
});

export const ForgotPasswordFormSchema = z.object({
  email: z.string().email(),
});

export const ResetPasswordFormSchema = z
  .object({
    password: z.string().min(6).max(32),
    confirmPassword: z.string(),
  })
  .superRefine((payload, ctx) => {
    if (payload.password !== payload.confirmPassword) {
      ctx.addIssue({
        code: "custom",
        message: "Passwords don't match",
        path: ["confirmPassword"]
      });
    }
  });
