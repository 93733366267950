import { env } from "src/lib/env";
import { axiosAuth } from "./auth";

export const getBooksOrdered = async () => {
  const { status, data } = await axiosAuth().get(
    `${env.REACT_APP_API_URL}/checkbookuser`,
  );

  return { status, eBooks: data.data.booksOrdered };
};

export const getBookOutline = async (bookName: string) => {
  const { status, data } = await axiosAuth().get(
    `${env.REACT_APP_API_URL}/getoutline?book=${bookName}`,
  );

  return { status, data: data };
};

export const startReader = async (
  pageNumber: number,
  job: string,
  token: string,
  part: 1 | 2,
) => {
  const payload = {
    action: "start",
    index: pageNumber,
    booktype: job.toLowerCase(),
    book: `part-${part}`,
    secrettoken: token,
  };

  const { status, data } = await axiosAuth().post(
    `${env.REACT_APP_API_URL}/readebook`,
    payload,
  );

  return { status, data };
};

export const getNextTenPages = async (
  pageNumber: number,
  job: string,
  token: string,
  part: 1 | 2,
) => {
  const payload = {
    action: "next",
    index: pageNumber,
    booktype: job.toLowerCase(),
    book: `part-${part}`,
    secrettoken: token,
  };

  const { status, data } = await axiosAuth().post(
    `${env.REACT_APP_API_URL}/readebook`,
    payload,
  );

  return { status, data };
};

export const getPrevTenPages = async (
  pageNumber: number,
  job: string,
  token: string,
  part: 1 | 2,
) => {
  const payload = {
    action: "prev",
    index: pageNumber,
    booktype: job.toLowerCase(),
    book: `part-${part}`,
    secrettoken: token,
  };

  const { status, data } = await axiosAuth().post(
    `${env.REACT_APP_API_URL}/readebook`,
    payload,
  );

  return { status, data };
};

export const jumpToPage = async (
  pageNumber: number,
  job: string,
  token: string,
  part: 1 | 2,
) => {
  const payload = {
    index: pageNumber,
    booktype: job.toLowerCase(),
    book: `part-${part}`,
    secrettoken: token,
  };

  const { status, data } = await axiosAuth().post(
    `${env.REACT_APP_API_URL}/readebook2`,
    payload,
  );

  return { status, data: data };
};
