import { StickyBranding } from "src/components/ui/sticky-branding";
import { ResetPasswordForm } from "./reset-password-form";

export const ResetPasswordView = () => {
  return (
    <div className="flex justify-center items-center h-screen flex-col gap-5">
      <StickyBranding />
      <h1 className="text-3xl md:text-4xl font-bold">Create a new password</h1>
      <ResetPasswordForm />
    </div>
  );
};
