import { zodResolver } from "@hookform/resolvers/zod";
import { useState } from "react";
import { useForm } from "react-hook-form";
import { ForgotPasswordFormSchema } from "src/schemas/auth";
import { z } from "zod";
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from "src/components/ui/form";
import { FancyInput } from "src/components/ui/input";
import { CheckSpam } from "./check-spam";
import { ForgotPassword } from "src/redux/api/auth";
import { toast } from "sonner";
import { AxiosError } from "axios";
import { SubmitButton } from "src/components/ui/submit-button";

export const ForgotPasswordForm = () => {
  const form = useForm<z.infer<typeof ForgotPasswordFormSchema>>({
    resolver: zodResolver(ForgotPasswordFormSchema),
    defaultValues: {
      email: "",
    },
  });

  const [isPending, setIsPending] = useState(false);

  const onSubmit = (values: z.infer<typeof ForgotPasswordFormSchema>) => {
    setIsPending(true);
    ForgotPassword(values)
      .then((status) => {
        if (status === 200) {
          toast.success("Email sent");
        }
      })
      .catch((error) => {
        if (error instanceof AxiosError) {
          form.setError("email", {
            message: error.response?.data.message,
          });
        }
      })
      .finally(() => {
        setIsPending(false);
      });
  };

  return (
    <Form {...form}>
      <form
        className="flex flex-col items-center justify-center gap-3"
        onSubmit={form.handleSubmit(onSubmit)}
      >
        <FormField
          control={form.control}
          name="email"
          render={({ field }) => (
            <FormItem>
              <FormLabel className="text-lg">
                Enter your email and we'll send you a link
              </FormLabel>
              <FormControl>
                <FancyInput
                  label="Email"
                  error={form.formState.errors.email}
                  {...field}
                />
              </FormControl>
              <FormMessage />
            </FormItem>
          )}
        />
        <SubmitButton
          className="w-full"
          isPending={isPending}
          disabled={isPending || form.formState.isSubmitSuccessful}
        >
          Send Mail
        </SubmitButton>
        <CheckSpam />
      </form>
    </Form>
  );
};
