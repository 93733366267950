import { useSearchParams } from "react-router-dom";

interface QueryParams {
  [key: string]: string | null;
}

const useQueryParams = (keys: string[]): QueryParams => {
  const [searchParam] = useSearchParams();

  const queryParams: QueryParams = {};

  keys.forEach((key) => {
    queryParams[key] = searchParam.get(key);
  });

  return queryParams;
};

export default useQueryParams;
