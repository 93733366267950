import { useEffect, useState } from "react";
import { toast } from "sonner";
import { useFetchAllSlots } from "src/redux/api/hooks/useFetchAllSlots";
export const useFetchAllSlotDates = () => {
  const [dates, setDates] = useState<Date[]>([]);
  const { slots, isPending } = useFetchAllSlots();

  useEffect(() => {
    try {
      if (slots) {
        setDates(slots.map((slot) => new Date(slot.date)));
      }
    } catch (error) {
      console.error("slots not available!");
      toast.error("Please refresh the page!");
      window.location.reload();
    }
  }, [slots]);

  return { dates, isPending };
};
