import { useEffect, useState } from "react";
import { ScheduledExam } from "src/lib/entities";
import { getScheduledExams } from "../exam";

export const useFetchExams = () => {
  const [scheduledExams, setScheduledExams] = useState<
    { exam: ScheduledExam; isExamCloseForPost: boolean; isMissed: boolean }[]
  >([]);
  const [isPending, setIsPending] = useState(true);

  const isExamClose = (startTime: string) => {
    const examStartTime = new Date(startTime);
    const currentTime = new Date();

    const timeDifference = examStartTime.getTime() - currentTime.getTime();

    // Allow clicking if the exam start time is within the next 30 minutes
    // or the exam is up to 5 minutes late
    const isClose =
      timeDifference <= (30 + 10) * 60 * 1000 &&
      timeDifference >= -10 * 60 * 1000;

    return isClose;
  };

  const isExamMissed = (startTime: string) => {
    const examTime = new Date(startTime);
    const now = new Date();
    const diff = (now.getTime() - examTime.getTime()) / 1000 / 60;

    return diff >= 10;
  };

  useEffect(() => {
    const fetchScheduledExams = async () => {
      try {
        const { data } = await getScheduledExams();
        const promises = data.scheduledExam.map(async (exam: any) => {
          const isExamCloseForPost = isExamClose(exam.slot.startTime);
          const isMissed = isExamMissed(exam.slot.startTime);
          return { exam, isExamCloseForPost, isMissed };
        });
        const result = await Promise.all(promises);
        setScheduledExams(result);
      } catch (error) {
        console.error("Error fetching scheduled exams:", error);
      } finally {
        setIsPending(false);
      }
    };

    fetchScheduledExams();
  }, []);
  return { scheduledExams, isPending };
};
