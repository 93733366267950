import { useFetchCandidate } from "src/redux/api/hooks/useFetchCandidate";
import { Avatar, AvatarFallback, AvatarImage } from "./avatar";
import { env } from "src/lib/env";
import { User } from "lucide-react";

interface ProfilePictureProps {
  className?: string;
  src?: string;
}

export const ProfilePicture = ({ className, src }: ProfilePictureProps) => {
  const { candidate } = useFetchCandidate();
  return (
    <Avatar className={className}>
      <AvatarImage
        //do not use ?? operator below instead of || we want to check for falsy values
        src={
          src || `${env.REACT_APP_CLOUD_FRONT_URL}/${candidate?.profilePicture}`
        }
        alt={candidate?.candidateName}
      />
      <AvatarFallback>
        <User />
      </AvatarFallback>
    </Avatar>
  );
};
