import { DashboardLayout } from "../Dashboard/DashboardLayout";
import { EditProfileForm } from "./edit-profile-form";

export const EditProfileView = () => {
  return (
    <DashboardLayout>
      <main className="flex justify-center w-full max-w-lg mx-auto items-center">
        <EditProfileForm />
      </main>
    </DashboardLayout>
  );
};
