import { ScrollArea, ScrollBar } from "src/components/ui/scroll-area";
import {
  Sheet,
  SheetContent,
  SheetHeader,
  SheetTitle,
  SheetTrigger,
} from "src/components/ui/sheet";
import { Loader2, NotebookText } from "lucide-react";

export type SheetButtonProps = {
  page: number;
  title: string;
};

type SideBarProps = {
  buttons: SheetButtonProps[];
  onClick: (page: number) => void;
  isPendingPage?: {
    pageNumber: number;
    isPending: boolean;
  };
};

const SheetButton = ({
  page,
  title,
  onClick,
  isPending,
}: SheetButtonProps & {
  onClick: (page: number) => void;
  isPending: boolean;
}) => {
  if (!title) return null;
  return (
    <button
      disabled={isPending}
      onClick={() => onClick(page)}
      className="w-full cursor-pointer rounded-lg bg-primary p-1 text-start text-white"
    >
      <span className="flex items-center text-sm">
        {title} {isPending && <Loader2 className="ml-2 animate-spin" />}
      </span>
    </button>
  );
};

export const MobileSideBar = ({
  buttons,
  onClick,
  isPendingPage,
}: SideBarProps) => {
  return (
    <Sheet>
      <SheetTrigger>
        <NotebookText />
      </SheetTrigger>
      <SheetContent side="right">
        <SheetHeader>
          <SheetTitle>Contents</SheetTitle>
        </SheetHeader>
        <ScrollArea className="h-full">
          <div className="space-y-1">
            {buttons.map((button) => (
              <SheetButton
                isPending={
                  button.page === isPendingPage?.pageNumber &&
                  isPendingPage.isPending
                }
                key={`${button.title}-${button.page}`}
                title={`Page-${button.page} ${button.title}`}
                page={button.page}
                onClick={onClick}
              />
            ))}
          </div>
          <ScrollBar orientation="vertical" />
        </ScrollArea>
      </SheetContent>
    </Sheet>
  );
};

export const DesktopSideBar = ({
  buttons,
  onClick,
  isPendingPage,
}: SideBarProps) => {
  return (
    <div className="space-y-1">
      {buttons.map((button) => (
        <SheetButton
          isPending={
            button.page === isPendingPage?.pageNumber && isPendingPage.isPending
          }
          key={`${button.title}-${button.page}`}
          title={button.title}
          page={button.page}
          onClick={onClick}
        />
      ))}
    </div>
  );
};
