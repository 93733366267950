import { useForm } from "react-hook-form";
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormMessage,
} from "src/components/ui/form";
import { zodResolver } from "@hookform/resolvers/zod";
import { SignInFormSchema } from "src/schemas/auth";
import { z } from "zod";
import { FancyInput } from "src/components/ui/input";
import { useState } from "react";
import { TermsAgreement } from "./terms-agreement";
import { SignIn } from "src/redux/api/auth";
import { useNavigate, useSearchParams } from "react-router-dom";
import { AxiosError } from "axios";
import { PasswordToggleWrapper } from "src/components/ui/password-toggle-wrapper";
import { SubmitButton } from "src/components/ui/submit-button";

export const SignInForm = () => {
  const [hasAgreedTerms, setHasAgreedTerms] = useState<boolean>(false);

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [searchParams, _] = useSearchParams();

  const callbackUrl = searchParams.get("callback");

  const form = useForm<z.infer<typeof SignInFormSchema>>({
    resolver: zodResolver(SignInFormSchema),
  });

  const [isPending, setIsPending] = useState(false);

  const navigate = useNavigate();

  const onSubmit = (values: z.infer<typeof SignInFormSchema>) => {
    setIsPending(true);
    SignIn(values)
      .then((code) => {
        if (code === 200) {
          const redirectHref = callbackUrl
            ? decodeURIComponent(callbackUrl)
            : "/";
          window.location.href = redirectHref
        }
      })
      .catch((error) => {
        if (error instanceof AxiosError && error.response?.status === 403) {
          form.reset();
          form.setError("email", {
            message: "",
          });
          form.setError("password", {
            message: "Invalid email or password",
          });
        }
      })
      .finally(() => {
        setIsPending(false);
      });
  };

  return (
    <Form {...form}>
      <form
        onSubmit={form.handleSubmit(onSubmit)}
        className="flex flex-col gap-4"
      >
        <FormField
          control={form.control}
          name="email"
          render={({ field }) => (
            <FormItem>
              <FormControl>
                <FancyInput
                  label="Email"
                  error={form.formState.errors.email}
                  {...field}
                />
              </FormControl>
              <FormMessage />
            </FormItem>
          )}
        />
        <FormField
          control={form.control}
          name="password"
          render={({ field }) => (
            <FormItem>
              <FormControl>
                <PasswordToggleWrapper>
                  <FancyInput
                    label="Password"
                    error={form.formState.errors.password}
                    {...field}
                  />
                </PasswordToggleWrapper>
              </FormControl>
              <FormMessage />
            </FormItem>
          )}
        />
        <TermsAgreement
          onCheckedChange={setHasAgreedTerms}
          checked={hasAgreedTerms}
        />
        <SubmitButton
          className="w-full"
          isPending={isPending}
          disabled={
            isPending || !hasAgreedTerms
          }
        >
          Sign in
        </SubmitButton>
        <a href="/forgot" className="text-primary hover:underline">
          Forgot Password?
        </a>
      </form>
    </Form>
  );
};
