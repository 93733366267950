import { Candidate } from "src/lib/entities";
import { useEffect, useState } from "react";
import { fetchCandidate } from "../candidate";

export const useFetchCandidate = () => {
  const [candidate, setCandidate] = useState<Candidate>();
  const [isPending, setIsPending] = useState(true);

  useEffect(() => {
    fetchCandidate()
      .then((candidate) => {
        setCandidate(candidate);
      })
      .finally(() => {
        setIsPending(false);
      });
  }, []);

  return { candidate, isPending };
};
