import { DashboardLayout } from "../Dashboard/DashboardLayout";
import { Ebooks } from "./e-books";

export const BooksView = () => {
  return (
    <DashboardLayout>
      <main className="flex flex-col px-2 py-3">
        <h1 className="mb-5 mt-10 text-2xl  lg:text-3xl">Your E-Books</h1>
        <p className="mb-10 text-sm">
          Already purchased? Click <b>"Read Now"</b> to dive in! Haven't
          purchased your copy yet? Order your ebook today and start reading
          right away to increase the chances to qualify the exam!
        </p>
        <Ebooks />
      </main>
    </DashboardLayout>
  );
};
