import { Button } from "src/components/ui/button";
import React from "react";
import {
  Sheet,
  SheetClose,
  SheetContent,
  SheetDescription,
  SheetHeader,
  SheetTitle,
  SheetTrigger,
} from "src/components/ui/sheet";

interface NotificationSheetProps {
  children: React.ReactNode;
}

export const NotificationSheet = ({ children }: NotificationSheetProps) => {
  return (
    <Sheet>
      <SheetTrigger>{children}</SheetTrigger>
      <SheetContent>
        <SheetHeader>
          <SheetTitle>Notifications</SheetTitle>
          <SheetDescription>No notifications available</SheetDescription>
        </SheetHeader>
        <section>
          <SheetClose>
            <Button className="absolute font-normal text-lg left-0 top-[225px] -z-10 w-[150px] origin-bottom-left -translate-y-1/2 -rotate-90 transform rounded-b-none border-2 border-b-0 border-primary bg-white text-black">
              Hide
            </Button>
          </SheetClose>
        </section>
      </SheetContent>
    </Sheet>
  );
};
