import { useEffect, useState } from "react";
import { AppliedJob } from "src/lib/entities";
import { getAppliedJobs } from "../applied-post";

export const useFetchAppliedJobs = () => {
  const [appliedJobs, setAppliedJobs] = useState<AppliedJob[]>([]);
  const [isPending, setIsPending] = useState(true);

  useEffect(() => {
    getAppliedJobs()
      .then((appliedJobs: AppliedJob[]) => {
        setAppliedJobs(appliedJobs);
      })
      .finally(() => {
        setIsPending(false);
      });
  }, []);
  return { appliedJobs, isPending };
};
