import disableDevtool from "disable-devtool";

export const onDevTools = () => {
  disableDevtool({
    ondevtoolopen(type, next) {
      alert(
        "We are tracking your activities please don't use development inspector. This kind of activity may suspend your candidature",
      );
      next();
    },
    disableSelect: true,
  });
};
