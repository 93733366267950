import { AuthRoutesPaths, PublicRoutesPaths } from "src/RouteManager";
import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { isTokenExpired } from "src/lib/utils";

export const useAuth = () => {
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    const token = localStorage.getItem("jtoken");
    const isAuthRoute = AuthRoutesPaths.includes(location.pathname);
    const isPublicRoute = PublicRoutesPaths.includes(location.pathname);
    setIsAuthenticated(!!token);

    if (token && isAuthRoute && !isTokenExpired()) {
      navigate("/");
    }
    
    if (!token && !isPublicRoute && !isAuthRoute && isTokenExpired()) {
      const callbackUrl = encodeURIComponent(window.location.href);
      navigate(`/sign-in?callback=${callbackUrl}`);
    }
  }, [location.pathname, navigate]);

  return { isAuthenticated };
};
