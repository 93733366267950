export const DesktopPoster = () => {
  return (
    <div className="bg-primary flex flex-col justify-center items-center min-h-screen gap-10">
      <div className="bg-white px-8 py-3 rounded-lg w-3/4 flex justify-center items-center">
        <img src="/signin-form-poster.svg" alt="" />
      </div>
      <div className="text-white font-normal text-xl">Examination Portal V2</div>
      <div className="fixed bottom-3 right-3 text-sm text-white">
        <span>
          Contact us at{" "}
          <a href="mailto:nhwm@nhwmindia.com" className="hover:underline">
            nhwm@nhwmindia.com
          </a>{" "}
          or{" "}
          <a href="tel:+913617968083" className="hover:underline">
            +91-3617968083
          </a>
        </span>
      </div>
    </div>
  );
};
