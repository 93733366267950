import { Button } from "src/components/ui/button";
import { SearchBar } from "./search-bar";
import { searchItems } from "./results";
import {
  Accordion,
  AccordionContent,
  AccordionItem,
  AccordionTrigger,
} from "src/components/ui/accordion";

export const FAQView = () => {
  return (
    <div className="md:px-10 md:py-20">
      <div className="flex h-[80vh] items-start">
        <div className="fixed right-3 top-3">
          <Button asChild>
            <a href="/">Go back</a>
          </Button>
        </div>
        <div className="hidden w-1/2 bg-white p-8 md:block">
          <img
            alt="Interior"
            className="object-cover "
            src="/signin-form-poster.svg"
          />
        </div>
        <div className="flex h-full max-h-[530px] flex-col justify-between space-y-10 bg-white p-8 md:w-1/2">
          <div className="flex h-full flex-col justify-between">
            <h2 className="text-2xl font-semibold tracking-wide text-red-400">
              Help center
            </h2>
            <div>
              <h1 className="mt-2 max-w-96 text-3xl font-bold leading-normal text-gray-800 md:text-5xl">
                How can We help You
              </h1>
              <p className="mt-4 max-w-96 text-gray-600">
                Our help center can instantly give you answers to many
                frequently asked questions.
              </p>
            </div>
            <div className="mt-6 w-full max-w-screen-md">
              <SearchBar accordionItems={searchItems} />
            </div>
          </div>
        </div>
      </div>
      <div>
        <div className="border-b-2 px-4 py-12 sm:px-6 lg:px-8">
          <h3 className="mb-4 text-3xl font-medium">General Questions</h3>
          <div className="grid grid-cols-1 gap-4 md:grid-cols-2">
            {searchItems.map((item) => (
              <Accordion
                className="w-full"
                collapsible
                type="single"
                key={item.value}
              >
                <AccordionItem value={item.value}>
                  <AccordionTrigger
                    className="text-start text-lg"
                    id={item.value}
                  >
                    {item.trigger}
                  </AccordionTrigger>
                  <AccordionContent>{item.content}</AccordionContent>
                </AccordionItem>
              </Accordion>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};
