import { Button } from "src/components/ui/button";
import { Dialog, DialogContent, DialogTrigger } from "src/components/ui/dialog";
import { DialogClose } from "@radix-ui/react-dialog";
import { Cross2Icon } from "@radix-ui/react-icons";
import { useNavigate } from "react-router-dom";

export const EbookDialog = ({
  hasBought,
  job,
}: {
  hasBought: boolean;
  job: string;
}) => {
  const enabledBooks = ["RWO", "ITWO", "HRWO", "AWO", "DWO"];

  const navigate = useNavigate();

  if (!hasBought) {
    return (
      <Button
        className="text-xs"
        onClick={() =>
          !hasBought && navigate(`/books/order-book?book=${job.toLowerCase()}`)
        }
      >
        {">"}
      </Button>
    );
  }

  return (
    <Dialog>
      <DialogTrigger asChild>
        <Button className="text-xs">{">"}</Button>
      </DialogTrigger>
      <DialogContent>
        <DialogClose asChild>
          <div className="fixed right-4 top-4 cursor-pointer rounded-lg bg-zinc-100 p-1">
            <Cross2Icon className="size-4" />
          </div>
        </DialogClose>
        <h2 className="text-center text-2xl">Choose a book</h2>
        <div className="grid w-full grid-cols-2">
          <div
            className=" flex cursor-pointer items-center justify-center border py-3 text-center hover:bg-zinc-100"
            onClick={() => {
              navigate(
                hasBought
                  ? `/books/read-book?book=${job}&part=1`
                  : `/books/order-book?book=${job.toLowerCase()}`,
              );
            }}
          >
            Part 1
          </div>
          <div
            className=" flex cursor-pointer flex-col items-center justify-center border py-3 text-center hover:bg-zinc-100"
            onClick={() => {
              if (enabledBooks.includes(job)) {
                navigate(
                  hasBought
                    ? `/books/read-book?book=${job}&part=2`
                    : `/books/order-book?book=${job.toLowerCase()}`,
                );
              }
            }}
          >
            Part 2
            <div className=" text-xs text-red-500">
              {!enabledBooks.includes(job) && "Books are currently unavailable"}
            </div>
          </div>
        </div>
      </DialogContent>
    </Dialog>
  );
};
