import { axiosAuth } from "./auth";

export const getAppliedJobs = async () => {
  try {
    const response = await axiosAuth().get(
      `${process.env.REACT_APP_API_URL}/getappliedjobs`,
    );
    return response.data.data.jobsapplied;
  } catch (error) {
    console.error("something went wrong", error);
  }
};
