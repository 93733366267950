import { Button } from "src/components/ui/button";
import {
  Dialog,
  DialogClose,
  DialogContent,
  DialogFooter,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from "src/components/ui/dialog";
import { Post } from "src/lib/entities";

export const PaymentDialog = ({
  guideBook,
  bookPrice,
}: {
  guideBook: Post;
  bookPrice: number;
}) => {
  return (
    <Dialog>
      <DialogTrigger asChild>
        <Button className="text-wrap text-start h-16">
          Click here to scan and pay Rs {bookPrice} for the{" "}
          {guideBook.toUpperCase()} Guidebook
        </Button>
      </DialogTrigger>
      <DialogContent>
        <DialogHeader>
          <DialogTitle>
            <b>Pay {bookPrice}/-</b> (Including GST + Registered Postal Cost)
          </DialogTitle>
        </DialogHeader>
        <div>
          <div className="text-base font-medium text-gray-700">
            Scan the following QR Code to pay
          </div>
          <div className="text-base text-gray-700">
            Recipient name: National Healthcare & Wellness Mission
          </div>
          <div className="flex items-center justify-center">
            <img
              alt="QR Code"
              className="aspect-auto rounded-lg object-cover lg:w-60 xl:w-72 2xl:w-80"
              src="https://nhwm-job-portal.s3.ap-south-1.amazonaws.com/payment/book_payment_v2.jpg"
            />
          </div>
        </div>
        <DialogFooter>
          <DialogClose asChild>
            <Button>Close</Button>
          </DialogClose>
        </DialogFooter>
      </DialogContent>
    </Dialog>
  );
};
