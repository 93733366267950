import { env } from "src/lib/env";
import { axiosAuth } from "./auth";
import { Post } from "src/lib/entities";

export const fetchSlots = async (post: Post, state:string) => {
  try {
    const response = await axiosAuth().post(
      `${env.REACT_APP_API_URL}/getslotavailability`,
      {
        catagory: post,
        state: state,
      },
    );
    return response.data.data.slots;
  } catch (error) {
    console.error(error, "something went wrong here!");
  }
};
