import { Button } from "./button";

interface FAQCardProps {
  className?: string;
}

export const FAQCard = ({ className }: FAQCardProps) => {
  return (
    <section className={className}>
      <div className="relative flex size-full flex-col items-center gap-y-3 rounded-2xl bg-white p-3 shadow-xl">
        <img
          alt="Helpful person"
          className="absolute -top-16 size-28"
          src="https://nhwm-job-portal.s3.ap-south-1.amazonaws.com/app-images/cc_logo.png"
          style={{
            aspectRatio: "120/120",
            objectFit: "cover",
          }}
        />
        <span className="pt-10 text-xl font-semibold">Need Help?</span>
        <pre className="text-wrap text-center font-sans text-sm">
          Visit FAQ Section for asking help and common questions or contact us
          at<b> +91 3617968083</b> for your queries
        </pre>
        <Button asChild className="w-[200px] bg-black text-white">
          <a href="/faq">FAQ</a>
        </Button>
      </div>
    </section>
  );
};
