import { Button } from "src/components/ui/button";
import { cn } from "src/lib/utils";

interface MobileSidebarButtonProps {
  children: React.ReactNode;
  className?: string;
}

export const MobileSidebarButton = ({
  children,
  className,
}: MobileSidebarButtonProps) => {
  return (
    <Button
      asChild
      size="lg"
      variant="outline"
      className={cn("w-full justify-start", className)}
    >
      {children}
    </Button>
  );
};
