import axios from "axios";
import { jwtDecode } from "jwt-decode";
import { env } from "src/lib/env";
import {
  ForgotPasswordFormSchema,
  ResetPasswordFormSchema,
  SignInFormSchema,
} from "src/schemas/auth";
import { z } from "zod";

export const SignIn = async (values: z.infer<typeof SignInFormSchema>) => {
  const { email, password } = values;

  const response = await axios.post(`${env.REACT_APP_API_URL}/login`, {
    email,
    password,
  });

  const token = response.data.data.token;
  const decodedToken: { exp: number } = jwtDecode(token);
  const expirationTime = decodedToken.exp * 1000;

  localStorage.setItem("jtoken", token);
  localStorage.setItem("tokenExpiration", expirationTime.toString());

  return response.status;
};

export const ForgotPassword = async (
  values: z.infer<typeof ForgotPasswordFormSchema>,
) => {
  const { email } = values;

  const response = await axios.post(`${env.REACT_APP_API_URL}/forgetpassword`, {
    email,
  });

  return response.status;
};

export const ResetPassword = async (
  values: z.infer<typeof ResetPasswordFormSchema>,
  token: string,
  email: string,
) => {
  const { password } = values;

  const response = await axios.post(`${env.REACT_APP_API_URL}/resetpassword`, {
    token,
    email,
    password,
  });

  return response.status;
};

export const axiosAuth = () => {
  const token = localStorage.getItem("jtoken") as string;

  const axiosInstance = axios.create({
    baseURL: env.REACT_APP_API_URL,
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
  });

  // Function to refresh token
  // const refreshAuthLogic = (failedRequest: any) =>
  //   axios
  //     .get(`${BASE_URL}/refreshtokens`, {
  //       headers: {
  //         refreshtoken: token,
  //       },
  //     })
  //     .then((response) => {
  //       const newToken = response.data.token;
  //       localStorage.setItem("token", newToken);
  //       failedRequest.response.config.headers[
  //         "Authorization"
  //       ] = `Bearer ${newToken}`;
  //       return Promise.resolve();
  //     });

  // axiosAuthRefresh(axiosInstance, refreshAuthLogic);

  return axiosInstance;
};
