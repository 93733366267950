import { Button } from "src/components/ui/button";
import { ChevronLeftIcon, ChevronRightIcon, Loader2 } from "lucide-react";

type PaginationProps = {
  onNext: () => void;
  onPrev: () => void;
  pending: boolean;
};

export const ReaderPagination = ({
  onNext,
  onPrev,
  pending,
}: PaginationProps) => {
  return (
    <div className="flex items-center justify-between py-3">
      <Button size="sm" variant="outline" onClick={onPrev}>
        <ChevronLeftIcon className="h-4 w-4" />
        Previous
      </Button>
      <Button size="sm" variant="outline" onClick={onNext}>
        {pending ? "Loading" : "Next"}
        <ChevronRightIcon className="h-4 w-4" />
      </Button>
    </div>
  );
};
