import { ProfilePicture } from "src/components/ui/profile-picture";
import { Button } from "src/components/ui/button";
import {
  Form,
  FormField,
  FormControl,
  FormLabel,
  FormMessage,
  FormItem,
} from "src/components/ui/form";
import { Input } from "src/components/ui/input";
import { Textarea } from "src/components/ui/textarea";
import { EditProfileSchema } from "src/schemas/edit-profile";
import { useFetchCandidate } from "src/redux/api/hooks/useFetchCandidate";
import { editProfile } from "src/redux/api/candidate";
import { zodResolver } from "@hookform/resolvers/zod";
import { Loader2 } from "lucide-react";
import { useEffect, useRef, useState } from "react";
import { useForm } from "react-hook-form";
import { toast } from "sonner";
import { z } from "zod";

export const EditProfileForm = () => {
  const { candidate } = useFetchCandidate();

  const form = useForm<z.infer<typeof EditProfileSchema>>({
    resolver: zodResolver(EditProfileSchema),
  });

  const profilePictureRef = useRef<React.ElementRef<"input">>(null);

  const [pfpPreview, setPfpReview] = useState("");

  const [isPending, setIsPending] = useState(false);

  const onSubmit = (values: z.infer<typeof EditProfileSchema>) => {
    setIsPending(true);
    editProfile(values)
      .then(({ success }) => {
        if (success) {
          toast.success(success);
        }
      })
      .catch(() => {
        toast.error("Edit profile unsuccessful");
      })
      .finally(() => {
        setIsPending(false);
      });
  };

  useEffect(() => {
    if (candidate) {
      form.setValue("address", candidate.address || "");
      form.setValue("phoneNumber", candidate.whatsAppNo || "");
      form.setValue("aadharNumber", candidate.adharNumber || "");
    }
  }, [candidate, form]);

  return (
    <Form {...form}>
      <form
        onSubmit={form.handleSubmit(onSubmit)}
        className="flex w-full flex-grow flex-col gap-y-3 p-3"
      >
        <div className="flex flex-col items-center justify-center self-center">
          <div onClick={() => profilePictureRef.current?.click()}>
            <ProfilePicture
              className="size-24 cursor-pointer"
              src={pfpPreview}
            />
          </div>
          <h2 className="text-xl font-bold">{candidate?.candidateName}</h2>
        </div>
        <FormField
          control={form.control}
          name="profilePicture"
          render={({ field: { value, onChange, ...fieldProps } }) => (
            <FormItem>
              <FormControl>
                <Input
                  {...fieldProps}
                  type="file"
                  className="hidden"
                  accept="image/*"
                  ref={profilePictureRef}
                  onChange={(event) => {
                    const file = event.target.files && event.target.files[0];
                    onChange(file);
                    if (file) {
                      const reader = new FileReader();
                      reader.onload = (e) => {
                        setPfpReview(e.target?.result as string);
                      };
                      reader.readAsDataURL(file);
                    }
                  }}
                />
              </FormControl>
              <FormMessage />
            </FormItem>
          )}
        />
        <FormField
          control={form.control}
          name="address"
          render={({ field }) => (
            <FormItem>
              <FormLabel>Postal address</FormLabel>
              <FormControl>
                <Textarea {...field} className="max-h-[100px]" />
              </FormControl>
              <FormMessage />
            </FormItem>
          )}
        />
        <FormField
          control={form.control}
          name="phoneNumber"
          render={({ field }) => (
            <FormItem>
              <FormLabel>Phone number</FormLabel>
              <FormControl>
                <Input {...field} />
              </FormControl>
              <FormMessage />
            </FormItem>
          )}
        />
        <FormField
          control={form.control}
          name="aadharNumber"
          render={({ field }) => (
            <FormItem>
              <FormLabel>Aadhar card number</FormLabel>
              <FormControl>
                <Input {...field} />
              </FormControl>
              <FormMessage />
            </FormItem>
          )}
        />
        <Button disabled={isPending}>
          {isPending ? (
            <Loader2 className="mx-auto animate-spin" />
          ) : (
            "Save details"
          )}
        </Button>
      </form>
    </Form>
  );
};
