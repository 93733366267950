import { type ClassValue, clsx } from "clsx";
import moment from "moment";
import { twMerge } from "tailwind-merge";

export function cn(...inputs: ClassValue[]) {
  return twMerge(clsx(inputs));
}

export const isValidJwt = (token: string): boolean => {
  try {
    const parts = token.split(".");
    return parts.length === 3;
  } catch (error) {
    return false;
  }
};

// check token expiration
export const isTokenExpired = () => {
  const expirationTime = localStorage.getItem("tokenExpiration");
  if (!expirationTime) return true;

  const currentTime = Date.now();
  return currentTime > parseInt(expirationTime, 10);
};

// convert date to IST
export const convertDateToIST = (dateString: string) => {
  if (!dateString) return "Invalid Date";

  const options: Intl.DateTimeFormatOptions = {
    timeZone: "Asia/Kolkata",
    day: "numeric",
    month: "short",
    year: "numeric",
  };

  const formatter = new Intl.DateTimeFormat("en-IN", options);
  return formatter.format(new Date(dateString));
};

// convert time to IST
export const convertTimeToIST = (time: string) => {
  const options: Intl.DateTimeFormatOptions = {
    timeZone: "Asia/Kolkata",
    hour: "numeric",
    minute: "numeric",
  };
  const formatter = new Intl.DateTimeFormat("en-IN", options);
  return formatter.format(new Date(time));
};

export const calculateDuration = (startTime: string, endTime: string) => {
  const options: Intl.DateTimeFormatOptions = {
    hour: "numeric",
    minute: "numeric",
    second: "numeric",
  };

  const startDateTime = new Date(startTime);
  const endDateTime = new Date(endTime);

  const durationInMilliseconds =
    endDateTime.getTime() - startDateTime.getTime();

  const formatter = new Intl.DateTimeFormat("en-IN", options);
  const formattedDuration = formatter.format(new Date(durationInMilliseconds));

  return formattedDuration;
};

export const calculateReportingTime = (startTime: string) => {
  const startDateTime = new Date(startTime);

  // Subtract 30 minutes (30 * 60 * 1000 milliseconds) from the start time
  const reportingTime = new Date(startDateTime.getTime() - 30 * 60 * 1000);

  return convertTimeToIST(reportingTime.toISOString());
};

export const generateUUID = () => {
  return crypto.randomUUID();
};

export const formatDate = (date: string) => {
  const newDate = moment(date);
  const formatDate = newDate.toISOString();

  return formatDate;
};

export function getSubmissionId(admitnumber: string) {
  const match = admitnumber.match(/\d+$/);
  if (match) {
    return match[0];
  } else {
    return "";
  }
}

export const formatTimer = (seconds: number) => {
  const hours = Math.floor(seconds / 3600);
  const minutes = Math.floor((seconds % 3600) / 60);
  const remainingSeconds = seconds % 60;
  return `${String(hours).padStart(2, "0")}:${String(minutes).padStart(
    2,
    "0",
  )}:${String(remainingSeconds).padStart(2, "0")}`;
};
